import styled from 'styled-components'

export const Overlay = styled.div`
  display: ${props => props.hidden? 'none': 'flex'};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  z-index: 5;
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.89);
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  h2{
    align-self: flex-start;
  }

`
export const ModalContainer = styled.div`
    
    max-width: 550px;
    max-height: 90vh;
    background: var(--white);
    padding: 2.5%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 5px;
    transition: 0.2s;
    overflow-y: auto;
    overflow-x: hidden;

    svg{
        align-self: flex-end;
    }

    svg:hover{
      cursor: pointer;
      filter: brightness(1.2);
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background: var(--grey-lighter);
        height: 300px !important;
        border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background:var(--grey);
    }
    
`

export const ModalText = styled.div`
  font-family: Nunito;
  font-weight: 600;
  font-size: 13.5px;
  line-height: 19px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;

  color: var(--grey-lighter);

`

export const ModalButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media(max-width:540px){
    flex-direction: column;
    align-items: center;
    gap: 10px;
  } 

`

export const ModalConfirmButton = styled.button`
  max-width: 100%;
  width: 200px;
  height: 60px;
  border-radius: 5px;
  background: ${props => props.color};
  border: 0;
  outline: none;

  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: var(--white);

  transition: 0.2s;

  &:hover{
    cursor: pointer;
    filter: brightness(1.2);
  }
`