import React, { useEffect, useState } from 'react';

import { baseApiLead } from '../../../../services/baseApiLead';
import FirstStepForm from './FirstStepForm';
import SecondStepForm from './SecondStepForm';

import { token_exact } from '../../../../environment/environment.json';

import WhatsappIcon from '../../../../images/whatsapp.svg';

import {
    BtnWpp,
    Container,
    FormContainer,
    IconWpp,
    LoadingContainer,
    Spinner,
    Title,
} from './styles';
import { Iframe, SubTitle, Paragraph } from '../../../../styles';
import axios from 'axios';

export interface ILeadData {
    occupation?: string;
    port?: string;
    name?: string;
    email?: string;
    phone?: string;
    cpf?: string;
    presencial?: string;
}

export default function FormComponent() {
    const [feedBackStep, setFeedBackStep] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [stepOneData, setStepOneData] = useState<ILeadData>();
    const [stepTwoData, setStepTwoData] = useState<ILeadData>();

    useEffect(() => {
        localStorage.removeItem('leadIdExact');
        localStorage.removeItem('contactIdExact');
    }, []);

    async function handleRegisterLead(data: ILeadData, step: number) {
        setLoading(true);
        if (step === 1) {
            setStepOneData(data);
            const { name, email, phone, cpf, presencial } = data;
            const phoneNumber = phone?.replace(/\D/g, '');
            localStorage.setItem('lead_email', email || '');
            const laharIdentifier = localStorage.getItem('page_identifier');
            const promise = baseApiLead(`/leads/register-new-lead/${step}`, {
                method: 'POST',
                data: {
                    name,
                    email,
                    phone: phoneNumber,
                    origin: localStorage.getItem('origin'),
                    campaign: localStorage.getItem('campaign'),
                    laharIdentifier,
                    cpf,
                    presencial
                },
            });

            const promise2 = baseApiLead.post(
                '/leads/register-new-lead-on-exact/1',
                {
                    name,
                    phone: phoneNumber,
                    campaign: localStorage.getItem('campaign'),
                    source: 'Evento',
                    subSource: localStorage.getItem('page_identifier'),
                    form: laharIdentifier + ' ETAPA 01',
                    email,
                    id: localStorage.getItem('leadIdExact'),
                    contactId: localStorage.getItem('contactIdExact'),
                    port: localStorage.getItem('port') || '.',
                    occupation: localStorage.getItem('occupation') || '.',
                },
                {
                    headers: {
                        token_exact,
                    },
                }
            );

            await Promise.all([promise, promise2]).then((response) => {
                localStorage.setItem('leadIdExact', response[1].data.leadId);
                localStorage.setItem(
                    'contactIdExact',
                    response[1].data.contactId
                );

                if (response[0].status && response[1].status) {
                    setLoading(false);
                    setFeedBackStep(2);
                }
            });
        }

        if (step === 2) {
            setStepTwoData(data);
            const { occupation, port } = data;
            const email = localStorage.getItem('lead_email');
            const laharIdentifier = localStorage.getItem('page_identifier');

            const promise = baseApiLead(`/leads/register-new-lead/${step}`, {
                method: 'POST',
                data: {
                    occupation,
                    port,
                    email,
                    laharIdentifier,
                },
            });

            const promise2 = baseApiLead.put(
                '/leads/register-new-lead-on-exact/2',
                {
                    occupation,
                    port,
                    campaign: localStorage.getItem('campaign'),
                    source: 'Evento',
                    subSource: localStorage.getItem('page_identifier'),
                    form: laharIdentifier + ' ETAPA 02',
                    id: localStorage.getItem('leadIdExact'),
                },
                {
                    headers: {
                        token_exact,
                    },
                }
            );

            await Promise.all([promise, promise2]).then((response) => {
                if (response[0].status && response[1].status) {
                    setLoading(false);
                    setFeedBackStep(3);
                }
            });

            port && localStorage.setItem('port', port);
            occupation && localStorage.setItem('occupation', occupation);
        }
    }

    return (
        <FormContainer>
            {feedBackStep === 1 && (
                <>
                    <Title>
                        Preencha seus dados para participar. <small> 1/2</small>
                    </Title>
                    <FirstStepForm registerLead={handleRegisterLead} />
                    {loading && (
                        <LoadingContainer>
                            <Spinner />
                        </LoadingContainer>
                    )}
                </>
            )}
            {feedBackStep === 2 && (
                <>
                    <Title>
                        Preencha seus dados para participar. <small>2/2</small>
                    </Title>
                    <SecondStepForm registerLead={handleRegisterLead} />
                    {loading && (
                        <LoadingContainer>
                            <Spinner />
                        </LoadingContainer>
                    )}
                </>
            )}
            {feedBackStep === 3 && (
                <Container>
                    <SubTitle>
                        Parabéns! Você acabou de se inscrever para participar do TOTVS AGRO DAY!!
                    </SubTitle>
                    <Paragraph>
                        Entre no grupo de whatsapp e fique por dentro de tudo!
                    </Paragraph>
                    <br />
                    <BtnWpp href="https://chat.whatsapp.com/En1QnuWpeDACOVY3RQSKmq">
                        <IconWpp src={WhatsappIcon} alt="Whatsapp" />
                        Entrar no grupo
                    </BtnWpp>
                </Container>
            )}
        </FormContainer>
    );
}
