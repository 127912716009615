import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { FiArrowLeft } from 'react-icons/fi'
import 'rc-slider/assets/index.css'
import { Formik, Field } from 'formik'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { AdminContainer, Input, PageSubTitle, PageTitle, Label, EnterButton, Error, InputGroup } from '../globalStyles'
import { LogoImg, Header, IconContainer, ReturnText, Form, GridContainer, FlexContainer } from './styles'
import logo from '../../../images/logo-dark.png'
import Slider from '../../../components/Admin/Slider'
import { UserRegisterSchema } from '../../../schemas/userSchemas'
import { api } from '../../../services/Admin/api'
import { IUser } from '../../../interfaces/User'
import MaskedInput from 'react-text-mask'

export function Register() {
  const [isActive, setIsActive] = useState(false)

  const history = useHistory()

  const mask = [
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/
  ];

  function handleNavigateToUsersManager() {
    history.push('/admin/usuarios')
  }

  async function handleRegisterNewUser(data: IUser) {
    try {
      await api.post('/users/create', data)
      toast.success('Usuário cadastrado com sucesso!')
      setTimeout(()=>history.push('/admin/usuarios'), 4000)
    } catch (error: any) {
      if (error.response.data) {
        toast.warning(error.response.data.error)
        if(error.response.status === 401) setTimeout( () => history.push('/admin/login'), 3000)
      }
    }
  }

  return (
    <AdminContainer>
      <ToastContainer />
      <Header>
        <LogoImg src={logo} />
        <IconContainer>
          <FiArrowLeft onClick={handleNavigateToUsersManager} color="var(--blue)" size={24} />
          <ReturnText>Voltar para o inventário</ReturnText>
        </IconContainer>
      </Header>
      <Formik
        initialValues={{ name: '', email: '', cpf: '', password: '', confirmPassword: '', isAdmin: false }}
        onSubmit={values => handleRegisterNewUser({ ...values, isAdmin: isActive })}
        validationSchema={UserRegisterSchema}
      >
        {({ errors, touched, handleSubmit }) => (
          <Form>
            <PageTitle>Cadastro de usuário</PageTitle>
            <FlexContainer>
              <PageSubTitle>Dados</PageSubTitle>
              <Slider setSlider={setIsActive} isActive={isActive} />
            </FlexContainer>

            <InputGroup>
              <Label htmlFor="name" >Nome:</Label>
              < Field as={Input} name="name" width="100%" />
              {(errors.name && touched.name) && (<Error>{errors.name}</Error>)}
            </InputGroup>

            <GridContainer>

              <InputGroup>
                <Label htmlFor="email" >E-mail:</Label>
                <Field as={Input} name="email" />
                {(errors.email && touched.email) && (<Error>{errors.email}</Error>)}
              </InputGroup>

              <InputGroup>
                <Label htmlFor="cpf" >CPF:</Label>
                <Field name="cpf" placeholder="Seu CPF" render={({ field }: any) => (
                  <Input {...field} placeholder="Seu CPF" as={MaskedInput} mask={mask} />
                )} />
                {(errors.cpf && touched.cpf) && (<Error>{errors.cpf}</Error>)}
              </InputGroup>

              <InputGroup>
                <Label htmlFor="password" >Senha:</Label>
                <Field type="password" as={Input} name="password" />
                {(errors.password && touched.password) && (<Error>{errors.password}</Error>)}
              </InputGroup>

              <InputGroup>
                <Label htmlFor="confirmPassword" >Confirmar senha:</Label>
                <Field as={Input} type="password" name="confirmPassword" />
                {(errors.confirmPassword && touched.confirmPassword) && (<Error>As senhas não combinam</Error>)}
              </InputGroup>

            </GridContainer>

            <EnterButton type="submit" onClick={handleSubmit as () => void} >Cadastrar</EnterButton>

          </Form>
        )}
      </Formik>
    </AdminContainer>
  )
}