import React from "react";
import { useLocation } from "react-router";
import { FaWhatsapp, FaClipboardCheck } from 'react-icons/fa'
import { FiBookOpen, FiBriefcase, FiCalendar } from 'react-icons/fi'
import LogoImg from '../../images/TBCAGRO.png'
import { Image, Link, Main, Title } from './styles'


function Home() {
    const { search } = useLocation()

    return (
        <Main>
            <Image
                src={LogoImg}
                alt="logo TBC Agro"
            />

            <Title>Do que você precisa?</Title>

            <Link color="var(--green)" onClick={() => (window as any).lintrk('track', { conversion_id: 6087977 })}
                href="https://wa.me/message/6QLBNUTEZKJIE1"

            >
                <FaWhatsapp color="var(--white)" size={35} />
                Fale Conosco!
            </Link>

            <Link href="https://bit.ly/35zouMp">
                <FiCalendar color="var(--white)" size={35} />
                UNIVERSO TOTVS
            </Link>

            <Link href={search ? `https://porteira360.com.br/ebook/soja${search}` : "https://porteira360.com.br/ebook/soja"}>
                <FiBookOpen color="var(--white)" size={35} />
                Baixar E-book Gratuito da Soja
            </Link>

            <Link href={search ? `https://porteira360.com.br/ebook/milho${search}` : "https://porteira360.com.br/ebook/milho"}>
                <FiBookOpen color="var(--white)" size={35} />
                Baixar E-book Gratuito do Milho
            </Link>

            {/*<Link href="https://porteira360.com.br/one/cursos/especialista-contabil-protheus">
                <FiCalendar color="var(--white)" size={35} />
                Curso Especialista Contábil Protheus.
            </Link>*/}

            {/* <Link color="var(--orange)"  href="/vagas">
                <FiBriefcase color="var(--white)" size={35}/>
                Trabalhe conosco
            </Link> */}

        </Main>
    )
}

export default Home
