import styled from "styled-components";

export const HrContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 40px 0;
`

export const Hr = styled.div`
    display: flex;
    width: 70%;
    height: 1px;
    border-radius: 50%;
    background-color: var(--blue);
    justify-self: center;
`