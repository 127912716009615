import React from 'react'
import { FiEdit2, FiTrash, FiX } from 'react-icons/fi'
import { useAuth } from '../../../../hooks/useAuth'
import { IRoutine } from '../../../../interfaces/routine'

import { ModalContainer, Overlay } from '../global'
import { Header, HeaderIconsContainer, IconBackground, ModalTitle, ModalText, ModalTextBold, KeywordGrid } from './styles'

interface DetailsMNodalProps {
  isVisible: boolean,
  setIsVisible: (arg: boolean) => void,
  data: IRoutine,
  handleDelete: (arg: string) => void,
  handleUpdate: (arg: string) => void
}

export function DetailsModal(props: DetailsMNodalProps) {
  const { user } = useAuth()

  return (
    <Overlay hidden={!props.isVisible} >
      <ModalContainer>
        <Header>
          <ModalTitle>{props.data.name}</ModalTitle>
          <HeaderIconsContainer>
            {user?.isAdmin && (
              <>
                <IconBackground color="var(--blue)">
                  <FiEdit2 onClick={() => { props.handleUpdate(props.data._id as string); props.setIsVisible(false) }} color="var(--white)" size={20} />
                </IconBackground>

                <IconBackground color="var(--red)" >
                  <FiTrash onClick={() => { props.handleDelete(props.data._id as string); props.setIsVisible(false) }} color="var(--white)" size={20} />
                </IconBackground>
              </>
            )}

            <IconBackground color="var(--red)">
              <FiX onClick={() => props.setIsVisible(false)} color="var(--white)" size={20} />
            </IconBackground>
          </HeaderIconsContainer>
        </Header>

        <ModalText>
          {props.data.description}
        </ModalText>

        <ModalTitle>Observações</ModalTitle>
        <ModalText>
          {props.data.notes}
        </ModalText>

        <ModalTextBold>Palavras-chave:</ModalTextBold>
        <KeywordGrid>
          {props.data.keyWords.map(keyWord => (
            <ModalText>#{keyWord}</ModalText>
          ))}
        </KeywordGrid>

      </ModalContainer>
    </Overlay>
  )
}