import styled from 'styled-components'

export const Text = styled.span`
  font-family: Nunito;
  font-weight: 600;
  font-size: 13.5px;
  line-height: 19px;
  text-align: center;
  margin:5px;
  color: var(--grey-lighter);
`

export const HighLightedText = styled.span`
  font-family: Nunito;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
  text-align: center;
  margin-top:20px;
  margin-bottom: 10px;
  color: var(--grey);
`

export const SubmitButton = styled.button`
  background: var(--blue);
  border:0;
  outline: none;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 100%;
  color: var(--white);
  font-size: 16px;
  font-family: Nunito, sans-serif;
  transition: 0.2s;

  &:hover{
    filter: brightness(1.3);
    cursor: pointer;
  }
`
