import React, { useEffect, useState } from 'react';
import { Image, Main, Title, Input, Label, InputGroup, TextArea, SubmitButton, Form, Error } from '../localStyles';
import logo from '../../../images/logo-totvs.svg'
import { Requirement } from './styles';
import { FiX } from 'react-icons/fi';
import { JobsModalAuth } from '../components/JobsModalAuth';
import { Job } from '../../../interfaces/job';
import { baseApi as api } from '../../../services/baseApi';
import { toast, ToastContainer } from 'react-toastify'
import { Formik } from 'formik';
import { RegisterJobSchema } from '../../../schemas/jobs';
import { useHistory, useLocation } from 'react-router-dom';
import { setTimeout } from 'timers';

export function JobRegister() {

  const [requirements, setRequirements] = useState<string[]>([])
  const [initialValues, setInitialValues] = useState<Job>()
  const [currentRequirement, setCurrentRequirement] = useState<string>('')
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const history = useHistory()
  const location = useLocation()

  function handleKeyPress(e: any) {
    if (e.code === 'Enter') {
      SetNewRequirementsToArray(currentRequirement)
    }
  }

  function SetNewRequirementsToArray(requiriment: string) {
    setRequirements(requirements => [...requirements, requiriment])
    setCurrentRequirement('')
  }

  function handleRemoveRequirements(requiriment: string) {
    const filteredArray = requirements.filter(item => {
      return item !== requiriment
    })
    setRequirements(filteredArray)
  }

  async function handleCreateJob(values: Job) {
    const token = localStorage.getItem('jobs_token')
    try {
      if (location.pathname === '/vagas/cadastro'){
        await api.post(`/jobs/create`, values, {
          headers: {
            'authorization': `Bearer ${token} `
          }
        })

        toast.success('Vaga cadastrado com sucesso');

      } else{
        await api.put(`/jobs/update/${(location.state as any).id}`, values, {
          headers: {
            'authorization': `Bearer ${token} `
          }
        }) 

        toast.success('Vaga atualizada com sucesso');
      }
    
      setTimeout(() => { history.push('/vagas/admin') }, 3000)
    } catch (error: any) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.error)
        console.log(error.response)
        if (error.response.status === 401) {
          setIsVisible(true)
        }
      }
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("jobs_token")
    if (!token) {
      setIsVisible(true)
    }

  }, [])

  useEffect(() => {

    async function loadCurrentJob() {
      try {
        const response = await api.get(`/jobs/details/${(location.state as any).id}`)
        return response
      } catch (error: any) {
        if (error.response && error.response.data) toast.error(error.response.data.error)
      }
    }

    if (location.pathname.includes('/vagas/atualizar')) {
      loadCurrentJob().then(response=> {

        setInitialValues({
          title: response?.data.job?.title,
          description: response?.data.job?.description,
          googleFormLink: response?.data.job?.googleFormLink,
          openDate: response?.data.job?.openDate,
          closeDate: response?.data.job?.closeDate ,
        })

        setRequirements(response?.data.job?.requirements)
        
      })
    } else {
      setInitialValues({
        title:'',
        description: '',
        googleFormLink: '',
        openDate: '',
        closeDate: '',
      })
    }
  }, [location])


  return (
    <Main>
      <Image src={logo} alt="TOTVS Brasil Central Agro" />
      <Title>{ location.pathname === '/vagas/cadastro'? 'Cadastrar' : 'Atualizar' } nova vaga</Title>
      {initialValues && (
        <Formik
          initialValues={initialValues}
          validationSchema={RegisterJobSchema}
          onSubmit={values => handleCreateJob({ ...values, requirements })}
        >
          {({ handleChange, handleSubmit, values, errors, touched }) => (
            <Form>
              <InputGroup>
                <Label htmlFor="title" >Título:</Label>
                <Input onChange={handleChange} defaultValue={values.title} name="title" />
                {(errors.title && touched.title) && <Error>{errors.title}</Error>}
              </InputGroup>

              <InputGroup>
                <Label htmlFor="description" >Descrição:</Label>
                <TextArea onChange={handleChange} defaultValue={values.description} name="description" />
                {(errors.description && touched.description) && <Error>{errors.description}</Error>}
              </InputGroup>

              <InputGroup>
                <Label htmlFor="googleFormLink" >Link do formulário google:</Label>
                <Input onChange={handleChange} defaultValue={values.googleFormLink} name="googleFormLink" />
                {(errors.googleFormLink && touched.googleFormLink) && <Error>{errors.googleFormLink}</Error>}
              </InputGroup>

              <InputGroup>
                <Label htmlFor="openDate" >Data de inicio da divulgação:</Label>
                <Input type="datetime-local"  onChange={handleChange} defaultValue={values.openDate.substring(0,16)} name="openDate" />
                {(errors.openDate && touched.openDate) && <Error>{errors.openDate}</Error>}
              </InputGroup>

              <InputGroup>
                <Label htmlFor="closeDate" >Data final da divulgação:</Label>
                <Input type="datetime-local"  onChange={handleChange} defaultValue={values.closeDate.substring(0,16)} name="closeDate" />
                {(errors.closeDate && touched.closeDate) && <Error>{errors.closeDate}</Error>}
              </InputGroup>


              <InputGroup>
                <Label htmlFor="requirements" > Requisito:</Label>
                <Input
                  value={currentRequirement}
                  onChange={e => setCurrentRequirement(e.target.value)}
                  onKeyPress={handleKeyPress}
                  placeholder="Tecle enter para adicionar o requisito..."
                  name="requirements" />
              </InputGroup>

              {requirements.map((requirement => (
                <Requirement key={requirement} >
                  {requirement}
                  <FiX color="var(--white)" size={24} onClick={() => handleRemoveRequirements(requirement)} />
                </Requirement>
              )))}

              <SubmitButton type="button" onClick={handleSubmit as () => void}>
                Enviar
              </SubmitButton>

            </Form>
          )}
        </Formik>
      )}
      <JobsModalAuth isVisible={isVisible} setIsVisible={setIsVisible} />
      <ToastContainer />
    </Main>
  )
}