import React from 'react'

import { CardContainer, CardContainerImage, CardImage, CardTitle, CardText } from './styles'

interface CardProps {
    image: string,
    title: string,
    text: string
}

function CardBenefits({ image, title, text }: CardProps) {
    return (
        <CardContainer>
            <CardContainerImage className="twb-image-container">
                <CardImage src={image} alt="Ícone" />
            </CardContainerImage>
            <CardTitle>{title}</CardTitle>
            <CardText>
                {text}
            </CardText>
      </CardContainer>
    )
}

export default CardBenefits
