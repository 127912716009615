import styled from 'styled-components'


export const SliderContent = styled.div`
    display: flex;
    flex-direction: row;
`

export const SliderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding:5px;
`

export const Circle = styled.div`
    width: 30px;
    height: 30px;
    background: ${props => props.className !== 'active'? 'var(--grey)' : 'var(--blue-darker)'};
    border-radius: 50%;
    position: absolute;
    margin-left: ${props => props.className!=='active'? '-5px' : '35px' };
    transition: 0.3s;
`

export const Bar = styled.div`
    width: 60px;
    height: 20px;
    background:${props => props.className !== 'active'? 'var(--grey-lighter)' : 'var(--aqua)'} ;
    border-radius: 5px;
    margin-top: 5px;
`

export const SliderLabel = styled.span`
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: var(--grey);
    margin-right: 10px;
`

export const SliderGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    

`