import React, { useEffect } from "react";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { LeadData } from "../../../interfaces/EbookLeadData";
import NumberFormat from "react-number-format";
import { handleLaharIntegration } from "../../../services/ebookApi";
import { ToastContainer, toast } from 'react-toastify';

import LogoImg from "../../../images/logo.png";
import EbookImg from "../../../images/ebook/ebook-art.png";

import statesArray from "../../../utils/states.json";

import * as Yup from 'yup'

import "./styles.css";

export function EbookMilho() {
  const notify = () => toast.success("O e-book foi enviado para o seu email!");

  const schema = Yup.object().shape({
    name: Yup.string().required("Este campo é obrigatório"),
    email: Yup.string().required("Este campo é obrigatório"),
    phone: Yup.string().required("Este campo é obrigatório"),
    state: Yup.string().required("Este campo é obrigatório"),
  })

  useEffect(() => {
    document.title = "Ebook de milho gratuito"
  }, [])

  return (
    <main className="page-container">
      <ToastContainer />
      <div className="container">
        <img
          className="image-logo"
          src={LogoImg}
          alt="Totvs | Unidade Brasil Central Agro"
        />
        <div className="headline">
          <div className="headline-container">
            <h1>Ebook de milho | </h1> 
            <h1><span>De <span id="span-price">197</span> por</span> R$ 0,00</h1>
          </div>
          <h2>Descubra os surpreendentes números do milho no Brasil que rendem milhões!</h2>
          <h3>Saia na frente mesmo em meio à crise e inflação</h3>
        </div>
        <section className="ebook-form-container">
          <div className="ebook-container">
            <div className="visual-element"></div>
            <img
              className="ebook-image"
              src={EbookImg}
              alt="Arte Ebook Arroz e Feijão"
            />
            <div className="visual-element-ebook"></div>
          </div>
          <div className="form-container">
            <h3>Preencha seus dados para receber o e-book</h3>
            <div className="visual-element-form-top"></div>
            <Formik
              validationSchema={schema}
              initialValues={{
                name: "",
                email: "",
                phone: "",
                state: "",
              }}
              onSubmit={async (
                values: LeadData,
                { setSubmitting }: FormikHelpers<LeadData>
                ) => {
                await handleLaharIntegration('EBOOK MILHO', null, null, values)
                notify()
                setSubmitting(false)
              }}
            >
              {({ errors }) => (
                <Form className="form">
                  <div className="field-group">
                    <label htmlFor="name">Nome</label>
                    <Field id="name" name="name" placeholder="Seu nome" />
                    {errors.name && ( <p className="error"> {errors.name} </p> )}
                  </div>
                  <div className="field-group">
                    <label htmlFor="email">Email</label>
                    <Field
                      id="email"
                      name="email"
                      placeholder="Seu melhor email"
                      type="email"
                    />
                  </div>
                  <div className="field-group">
                    <label htmlFor="phone">Celular</label>
                    <Field
                      as={NumberFormat}
                      format="(##) # ####-####" mask="_"
                      id="phone"
                      name="phone"
                      placeholder="Seu número de phone"
                    />
                  </div>
                  <div className="field-group">
                    <label htmlFor="state">Estado</label>
                    <Field as="select" id="state" name="state">
                      <option value="">Selecione</option>
                      {statesArray.map(({ value, state }) => {
                        return (
                          <option key={state} value={value}>
                            {state}
                          </option>
                        );
                      })}
                    </Field>
                  </div>
                  <button className="btn-form" type="submit">Quero o ebook</button>
                </Form>
              )}
            </Formik>
            <div className="visual-element-form"></div>
          </div>
        </section>
      </div>
    </main>
  );
}
