import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify';

import {
  AdminContainer, 
  FlexColumnDiv, 
  FlexRowDiv, 
  IconCircle, 
  Loader, 
  NotFoundContainer, 
  NotFoundImg, 
  PageSubTitle, 
  Header,
  IconContainer,
  IconGroup,
  IconText,
  LogoImg,
  Main,
  Menu,
  Table,
  TableHeader,
  TableHeaderText,
  TableRow,
  TopBar,
  Text,
  KeyWordContainer
} from '../globalStyles'
import logo from '../../../images/logo-dark.png'
import noData from '../../../images/noData.svg'

import { FiPower, FiUsers, FiHome, FiPlus, FiTrash, FiEye } from 'react-icons/fi'
import { SearchBar } from '../../../components/Admin/SearchBar'
import api from '../../../services/Admin/api'
import { useHistory } from 'react-router';
import { IRoutine } from '../../../interfaces/routine';
import { ConfirmModal } from '../../../components/Admin/modals/ConfirmModal';
import { useAuth } from '../../../hooks/useAuth';
import { DetailsModal } from '../../../components/Admin/modals/DetailsModal';
import { UserInfoModal } from '../../../components/Admin/modals/UserInfoModal';
import { useWindowDimensions } from '../../../hooks/useWIndowsDimensions';


export function Inventary() {
  const [routines, setRoutines] = useState<IRoutine[]>()
  const [isDeleteRoutineModalVisible, setIsDeleteRoutineModalVisible] = useState(false)
  const [isLogOutModalVisible, setIsLogOutModalVisible] = useState(false)
  const [selectedRoutine, setSelectedRoutine] = useState('')
  const [loadData, setLoadData] = useState(true)
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false)
  const [isUserInfoModalVisible, setIsUserInfoModalVisible] = useState(false)
  const [detailsData, setDetailsData] = useState<IRoutine>()
  const history = useHistory()
  const { clearUserFromStorage, user } = useAuth()
  const { width } = useWindowDimensions()

  async function loadRoutinesDataFromApi() {
    try {
      const response = await api.get('/routines/listen')
      setRoutines(response.data)
      setLoadData(false)
    } catch (error: any) {
      if (error.response) {
        toast.warning(error.response.data.error)
        if (error.response.status === 401) history.push('/admin/login')
      }
    }
  }

  function handleNavigateToCreateRoutine() {
    history.push('/admin/rotina/criar')
  }


  async function handleDeleteRoutine(id: string) {
    try {
      await api.delete(`/routines/delete/${id}`)
      setIsDeleteRoutineModalVisible(false)
      toast.success('Rotina deletada com sucesso!')
      setLoadData(true)
      document.location.reload()
    } catch (error: any) {
      toast.warning(error.response.data.error)
      if (error.response.status === 401) history.push('/admin/login')
    }
  }

  function handleUpdateRoutine(id: string) {
    history.push(`/admin/rotina/atualizar/${id}`)
  }

  function handleLogout() {
    clearUserFromStorage()
    history.push('/admin/login')
  }

  function handleDeleteClick(id: string) {
    setSelectedRoutine(id)
    setIsDeleteRoutineModalVisible(true)
  }

  function handleOpenDetailsModal(routine: IRoutine) {
    setIsDetailsModalVisible(true)
    setDetailsData(routine)
  }

  useEffect(() => {
    loadData && loadRoutinesDataFromApi()
  }, [history, loadData])

  return (
    <AdminContainer>
      <ConfirmModal
        title="Tem certeza que deseja excluir esse item?"
        isVisible={isDeleteRoutineModalVisible}
        setIsVisible={setIsDeleteRoutineModalVisible} handleClose={() => handleDeleteRoutine(selectedRoutine)} />

      <ConfirmModal
        title="Tem certeza que deseja sair dessa conta?"
        isVisible={isLogOutModalVisible}
        setIsVisible={setIsLogOutModalVisible} handleClose={handleLogout} />

      {detailsData &&
        <DetailsModal handleDelete={handleDeleteClick}
          handleUpdate={handleUpdateRoutine}
          data={detailsData}
          isVisible={isDetailsModalVisible}
          setIsVisible={setIsDetailsModalVisible}
        />
      }

      <UserInfoModal isVisible={isUserInfoModalVisible} setIsVisible={setIsUserInfoModalVisible} />

      <ToastContainer />
      <Header>
        <LogoImg src={logo} />
        <Menu>
          <IconGroup onClick={() => setIsLogOutModalVisible(true)}>
            <FiPower color="var(--red)" size={35} />
            <IconText color="var(--red)" >Sair</IconText>
          </IconGroup>

          {user?.isAdmin ? (
            <IconGroup onClick={() => history.push('/admin/usuarios')} >
              <FiUsers color="var(--grey-lighter)" size={35} />
              <IconText color="var(--grey-lighter)" >Usuários</IconText>
            </IconGroup>
          ) : (
            <IconGroup onClick={() => setIsUserInfoModalVisible(true)} >
              <FiUsers color="var(--blue)" size={35} />
              <IconText color="var(--blue)" >Perfil</IconText>
            </IconGroup>
          )}

          {user?.isAdmin && (
            <IconGroup onClick={() => history.push('/admin/inventario')} >
              <FiHome color="var(--blue)" size={35} />
              <IconText color="var(--blue)" >Home</IconText>
            </IconGroup>
          )}
        </Menu>
      </Header>

      <Main>
        <PageSubTitle>Suas rotinas</PageSubTitle>
        <TopBar>
          <SearchBar type="routines" setData={setRoutines} />
          {user?.isAdmin && (
            <FlexRowDiv>
              <Text>Adicionar nova rotina</Text>
              <IconContainer>
                <FiPlus onClick={handleNavigateToCreateRoutine} color="var(--white)" size={24} />
              </IconContainer>
            </FlexRowDiv>
          )}
        </TopBar>

        {loadData && (
          <Loader>x</Loader>
        )}

        {(routines?.length && routines.length > 0) && (
          <Table>
            <TableHeader>
              <TableHeaderText>Rotinas</TableHeaderText>
              {width > 900 && (
                <>
                  <TableHeaderText>Descrição</TableHeaderText>
                  <TableHeaderText>Observação</TableHeaderText>
                  <TableHeaderText>Palavras-chave</TableHeaderText>
                  <TableHeaderText>Ações</TableHeaderText>
                </>
              )}
            </TableHeader>
            {routines?.map(routine => (
              <TableRow key={routine._id} >
                {width > 900 ? (
                  <>
                    <Text>{routine.name}</Text>
                    <Text>{routine.description}</Text>
                    <Text>{routine.notes}</Text>

                    <KeyWordContainer>
                      {routine.keyWords[0] && <Text>#{routine.keyWords[0].length > 20 ? routine.keyWords[0].substring(0, 19) + '...' : routine.keyWords[0]}</Text>}
                      {routine.keyWords[1] && <Text>#{routine.keyWords[1].length > 20 ? routine.keyWords[1].substring(0, 19) + '...' : routine.keyWords[1]}</Text>}
                      {routine.keyWords[2] && <Text>#{routine.keyWords[2].length > 20 ? routine.keyWords[2].substring(0, 19) + '...' : routine.keyWords[2]}</Text>}
                      {routine.keyWords[3] && <Text>...</Text>}
                    </KeyWordContainer>
                  </>
                ) : (
                  <FlexColumnDiv>
                    <Text>
                      <strong>Nome:</strong> {routine.name}
                    </Text>
                    <Text>
                      <strong>descrição:</strong> {routine.description}
                    </Text>
                    <Text>
                      <strong>Observações:</strong> {routine.notes}
                    </Text>
                    <Text>
                      <strong>
                        Palavras-chave:
                      </strong>
                      {routine.keyWords.map((keyword, index) => (
                        <Text key={index} > #{keyword}</Text>
                      ))}
                    </Text>

                    <Text>
                      <strong>Ações:</strong>
                    </Text>
                  </FlexColumnDiv>
                )}
                <Text>
                  <FlexRowDiv>
                    <IconCircle onClick={() => handleOpenDetailsModal(routine)} color="var(--aqua)" >
                      <FiEye color="var(--white)" size={24} />
                    </IconCircle>

                    {user?.isAdmin && (
                      <IconCircle
                        onClick={() => handleDeleteClick(routine._id as string)}
                        color="var(--red)" >
                        <FiTrash color="var(--white)" size={24} />
                      </IconCircle>
                    )}
                  </FlexRowDiv>
                </Text>
              </TableRow>
            ))}
          </Table>
        )}

        {(!loadData && !routines?.length && routines?.length == 0) && (
          <NotFoundContainer>
            <NotFoundImg src={noData} alt="404 not found" />
            <PageSubTitle>Ainda não há rotinas cadastradas</PageSubTitle>
          </NotFoundContainer>
        )}

      </Main>

    </AdminContainer>
  )
}
