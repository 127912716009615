import React from 'react';
import logoTotvs from '../../images/logo.png';
import facebookIcon from '../../images/social-medias/facebook.svg';
import youtubeIcon from '../../images/social-medias/youtube.svg';
import linkedinIcon from '../../images/social-medias/linkedin.svg';
import instagramIcon from '../../images/social-medias/instagram.svg';

import SocialMedias from './SocialMedias';

import { ImageLogo, Paragraph } from '../../styles';
import {
    FooterContainer,
    IconsMediasContainer,
    SectionMediasContainer,
} from './styles';

type FooterProps = {
    backgroundColor?: string;
};

function Footer({ backgroundColor = 'var(--blue)' }: FooterProps) {
    return (
        <FooterContainer color={backgroundColor}>
            <ImageLogo src={logoTotvs} />
            <SectionMediasContainer>
                <Paragraph color="true">
                    Nos acompanhe nas redes sociais
                </Paragraph>
                <IconsMediasContainer>
                    <SocialMedias
                        img={linkedinIcon}
                        uri="linkedin.com/company/totvsbrasilcentralagro/"
                    />
                    <SocialMedias img={facebookIcon} uri="facebook.com/totvs" />
                    <SocialMedias
                        img={instagramIcon}
                        uri="instagram.com/totvsbrasilcentralagro/"
                    />
                    <SocialMedias
                        img={youtubeIcon}
                        uri="youtube.com/channel/UCkSBkhhEhcN-_Vi6AYY26pQ"
                    />
                </IconsMediasContainer>
            </SectionMediasContainer>
        </FooterContainer>
    );
}

export default Footer;
