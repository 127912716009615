import { createContext, ReactNode, useState } from 'react'
import { useHistory } from 'react-router'
import { IUser } from '../interfaces/User'


interface IAuthContextProviderProps{
    children: ReactNode
}

interface IAuthContext {
    user: IUser | null,
    storageUser : (user: IUser) => void,
    loadUserFromStorage : () => void,
    clearUserFromStorage: () => void
}

export const AuthContext = createContext<IAuthContext>({} as any) 

export const AuthContextProvider = (props: IAuthContextProviderProps) =>{
    const [user, setUser] = useState<IUser|null>(null)
    const history = useHistory()

    function storageUser(user: IUser){
        localStorage.setItem('user', JSON.stringify(user)) 
        setUser(user)
    }

    function loadUserFromStorage(){
        const user =  localStorage.getItem('user')
        if(user){
            setUser(JSON.parse(user))
        }
    }

    function clearUserFromStorage(){
        localStorage.removeItem('user')
        setUser(null)
    }

    return (
        <AuthContext.Provider value={{
            user,
            storageUser,
            loadUserFromStorage,
            clearUserFromStorage
            }}>
            {props.children}
        </AuthContext.Provider>
    )
}

