import * as Yup from "yup";

export const UserLoginSchema = Yup.object().shape({
    cpf: Yup.string().required("O campo CPF é obrigatório"),
    password: Yup.string().required("O campo Senha é obrigatório"),
})

export const UserRegisterSchema = Yup.object().shape({
    name: Yup.string().required('O campo nome é  obrigatório'),
    email: Yup.string().email().required('O campo e-mail é obrigatório'),
    cpf: Yup.string().required("O campo CPF é obrigatório"),
    password: Yup.string().required("O campo Senha é obrigatório"),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), '', 'As senhas não combinam']).required('O campo confirmar senha é obrigatório')
})

export const UserUpdateSchema = Yup.object().shape({
    name: Yup.string().required('O campo nome é  obrigatório'),
    email: Yup.string().email().required('O campo e-mail é obrigatório'),
    cpf: Yup.string().required("O campo CPF é obrigatório"),
    password: Yup.string(),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), '', 'As senhas não combinam'])
})

export const resetPassowrdSchema = Yup.object().shape({
    password: Yup.string().required('Senha é um campo obrigatório'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), '', 'As senhas não combinam'])
})

