import { IconSocialMedia, LinkToMedia } from './styles'

interface SocialMediasProps {
    uri: string,
    img: string
}

function SocialMedias({ img, uri }: SocialMediasProps) {
    return (
        <LinkToMedia href={`https://${uri}`} target="_blank">
            <IconSocialMedia src={img} />
        </LinkToMedia>
    )
}

export default SocialMedias
