import React from 'react'

import { FiX } from 'react-icons/fi'
import { PageSubTitle } from '../../../../pages/Admin/globalStyles'

import { ModalButtonsContainer, ModalConfirmButton, ModalContainer, ModalText, Overlay } from '../global'


interface ModalConfirmProps{
    isVisible: boolean,
    setIsVisible: (arg: boolean) => void,
    handleClose: (arg: any) => any
    title: string,
    description?: string
}

export function ConfirmModal(props:ModalConfirmProps){
    return(
        <Overlay hidden={!props.isVisible} >
            <ModalContainer>
                <FiX onClick={() => props.setIsVisible(false)} color="var(--red)" size={24} />
                <PageSubTitle> {props.title}</PageSubTitle>
                {props.description && (
                   <ModalText>
                       {props.description}
                   </ModalText>
                )}

                <ModalButtonsContainer>
                    <ModalConfirmButton onClick={props.handleClose}  color="var(--grey)" >Confirmar</ModalConfirmButton>
                    <ModalConfirmButton onClick={()=> props.setIsVisible(false)} color="var(--red)" >Cancelar</ModalConfirmButton>
                </ModalButtonsContainer>
                
            </ModalContainer>
        </Overlay>
    )
}