import { Formik } from 'formik'
import React from 'react'
import { FiX } from 'react-icons/fi'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { EnterButton, Error, Input, PageSubTitle,} from '../../../../pages/Admin/globalStyles'
import api from '../../../../services/Admin/api'
import { ModalContainer, ModalText, Overlay } from '../global'

interface Props {
  isVisible: boolean,
  setIsVisible: (arg: boolean) => void
}

export function RequestNewPassword({ isVisible, setIsVisible }: Props) {

  async function handleRequestNewPassword(email:string){
    try {
      await api.post('/users/request_password', {email} )
      toast.success('E-mail enviado com sucesso')
      setIsVisible(false)
    } catch (error: any) {
      if(error.response.data){
        toast.warning(error.response.data.error)
      }
    }
  }

  return (
    <Overlay hidden={!isVisible}>
      <ModalContainer>
        <FiX onClick={()=> setIsVisible(false)} color="var(--red)" size={24} />
        <PageSubTitle>Esqueceu a senha?</PageSubTitle>
        <ModalText>Informe seu e-mail abaixo e receba instruções de como recupera-la</ModalText>
        <Formik 
        initialValues={{email: ''}}
        onSubmit={values =>handleRequestNewPassword(values.email)}
        validationSchema={Yup.object().shape({email: Yup.string().email('Este e-mail não é válido').required('O Campo e-mail é obrigatório')})}
        >
          {({errors, touched, handleSubmit, handleChange})=>(
            <>
              <Input name="email" onChange={handleChange} width="100%" placeholder=" Informe seu e-mail" />
              {(errors.email && touched.email) && <Error>{errors.email}</Error>}
              <EnterButton type="submit" onClick={handleSubmit as ()=> void} >Enviar</EnterButton>
            </>
          )}
        </Formik>
      </ModalContainer>
    </Overlay>
  )
}