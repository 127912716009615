import styled from 'styled-components'

export const Header = styled.header`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

export const HeaderIconsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`

export const IconBackground = styled.div`
    background: ${props => props.color};
    border-radius: 50%;
    padding: 10px;
    align-items: center;
    justify-content: center;
    margin: 5px;
`

export const ModalTitle = styled.h2`
  font-family: Ubuntu;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  margin: 10px;
  color: var(--grey);
`

export const ModalTextBold = styled.span`
  font-family: Nunito;
  font-weight: bold;
  font-size: 18.3412px;
  line-height: 25px;
  color: var(--grey-lighter);
  margin: 10px;
`

export const ModalText = styled.span`
  font-family: Nunito;
  font-weight: 600;
  font-size: 13.7559px;
  line-height: 19px;
  text-align: justify;
  color: var(--grey);
  margin: 10px;
  
`

export const KeywordGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-auto-columns: auto;
  max-width: 100%;
 
  span{
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    white-space: nowrap;
  }

`