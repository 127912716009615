import React from 'react';
import { AuthContextProvider } from './contexts/authContext';
import Routes from './routes'

const App = () => {
  
  function saveOriginToStorage(){
    const params = new URLSearchParams(window.location.search)
    const origin = params.get('utm_source')
    const campaign = params.get('utm_campaign') 
    if (!window.location.pathname.includes('/admin') ) {
      localStorage.setItem('origin', origin || 'Acesso direto')
      localStorage.setItem('campaign', campaign || 'Acesso direto')
    } 
  }

  saveOriginToStorage()

  return (
    <AuthContextProvider>
      <Routes />
    </AuthContextProvider>
  );
}

export default App;
