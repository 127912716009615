import styled from 'styled-components'

export const SearchContainer = styled.div`
    background: var(--white);
    border-radius: 5px;
    padding: 5px;
`

export const SearchInput = styled.input`
    max-width: 350px;
    border: 0;
    outline: none;
    width: 90%;

    font-family: Nunito;
    font-size: 16px;
    line-height: 22px;
    color: var(--grey-lighter);
`