import { Formik } from 'formik';
import React from 'react';
import { Overlay } from '../../../../styles';
import { Error, Form, Input, InputGroup, Label, SubmitButton, Title } from '../../localStyles';
import { ModalContainer } from './styles';
import * as Yup from 'yup';
import { baseApi as api } from '../../../../services/baseApi';
import {toast} from 'react-toastify'

interface Props {
  isVisible: boolean,
  setIsVisible: (arg: boolean) => void
}

const passwordValidation = Yup.object().shape({ password: Yup.string().required('Esse campo é obrigatório') });

export function JobsModalAuth({ isVisible, setIsVisible }: Props) {

  async function signIn(password: string) {
    try {
      const response = await api.post('/jobs/auth', { password: password })
      if(response.data.token){
        localStorage.setItem('jobs_token', response.data.token);
        setIsVisible(false);
      }
    } catch (error: any) {
      if(error.response && error.response.data){
        toast.error(error.response.data.error)
      } else{
        toast.error(' Um erro desconhecido ocorreu. Tente novamente.')
      };
    }
  }

  return (
    <Overlay hidden={!isVisible}>
      <ModalContainer>
        <Title>Faça login para prosseguir</Title>
        <Formik
          initialValues={{ password: '' }}
          onSubmit={values => signIn(values.password)}
          validationSchema={passwordValidation}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            touched
          }) => (
            <Form>
              <InputGroup>
                <Label>Senha:</Label>
                <Input onChange={handleChange} type="password" name="password" placeholder="Informe uma senha" />
                {(errors.password && touched.password) && <Error>{errors.password}</Error>}
              </InputGroup>
              <SubmitButton onClick={handleSubmit as () => void} type="button">Enviar</SubmitButton>
            </Form>
          )}
        </Formik>
      </ModalContainer>
    </Overlay>
  )
}