import React from "react";
import { FiX } from "react-icons/fi";
import { Job } from "../../../../interfaces/job";
import { Overlay } from "../../../../styles";
import { ConvertToBrDate, ConvertToBrTime } from "../../../../utils/DateFormat";
import { Text, Title } from "../../localStyles";
import { Link, List, ListItem, ModalContainer } from "./styles";

interface Props {
  isVisible: boolean;
  setIsVisible: (arg: boolean) => void;
  job: Job;
  color: string;
}

export function JobsModalDetails({
  isVisible,
  setIsVisible,
  job,
  color,
}: Props) {

  function handleNavigateToFormOrEmail(){
    if(job.googleFormLink){
      return job.googleFormLink.includes("http")
      ? job.googleFormLink
      : `http://${job.googleFormLink}`
    } else{
      return `mailto:rh@tbcagro.com.br?Subject=Envio de curriculo para a vaga "${job.title}"`
    }
  }

  return (
    <Overlay hidden={!isVisible}>
      <ModalContainer>
        <FiX size={24} color="var(--red)" onClick={() => setIsVisible(false)} />
        <Title>{job.title}</Title>
        <Text className="medium-text">{job.description}</Text>
        <Text className="medium-text">Requisitos:</Text>
        <List>
          {job.requirements?.map((requirement) => (
            <ListItem key={requirement}>{requirement}</ListItem>
          ))}
        </List>

        <Text className="medium-text">
          <strong>Envio de curriculos: </strong>
          <Text className="medium-text" color="var(--green)">
            Dia {ConvertToBrDate(job.openDate as string)} às{" "}
            {ConvertToBrTime(job.openDate as string)}
          </Text>{" "}
          Até
          <Text className="medium-text" color="var(--red)">
            {" "}
            Dia {ConvertToBrDate(job.closeDate as string)} às{" "}
            {ConvertToBrTime(job.closeDate as string)}
          </Text>
        </Text>

        <Link
          color={color}
          href={handleNavigateToFormOrEmail()}
        >
          Quero a vaga!
        </Link>
      </ModalContainer>
    </Overlay>
  );
}
