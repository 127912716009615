import React from "react";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { useHistory, useLocation } from "react-router-dom";
import { Job } from "../../../../interfaces/job";
import { ConvertToBrDate, ConvertToBrTime } from "../../../../utils/DateFormat";
import { Button, Text } from "../../localStyles";
import { CardContainer, CardHeader, CardTitle, IconsContainer } from "./styles";

interface Props {
  execute: (args: any) => void;
  job: Job;
  onClick: (args: any) => void;
  openDeleteModal: () => void;
  color: string;
}

export function JobCard({
  execute,
  job,
  onClick,
  openDeleteModal,
  color,
}: Props) {
  const location = useLocation();
  const history = useHistory();

  function handleNavigateToUpdateJob() {
    history.push("/vagas/atualizar", {
      id: job._id,
    });
  }

  return (
    <CardContainer onClick={onClick}>
      <CardHeader>
        <CardTitle>{job.title}</CardTitle>
        {location.pathname === "/vagas/admin" && (
          <IconsContainer>
            <FiTrash2 color="var(--red)" onClick={openDeleteModal} size={18} />
            <FiEdit
              color="var(--blue)"
              size={18}
              onClick={handleNavigateToUpdateJob}
            />
          </IconsContainer>
        )}
      </CardHeader>

      <Text>
        {job.description.length > 200
          ? job.description.substring(0, 200) + "..."
          : job.description}
      </Text>

      <Text>
        <strong>Envio de curriculos: </strong>
        <Text color="var(--green)">
          Dia { ConvertToBrDate(job.openDate as string) } às {ConvertToBrTime(job.openDate as string)}
        </Text>{" "}
        Até
        <Text color="var(--red)">
          {" "}
          Dia { ConvertToBrDate(job.closeDate as string) } às {ConvertToBrTime(job.closeDate as string)}
        </Text>
      </Text>

      <Button color={color} onClick={execute}>
        Mais detalhes
      </Button>
    </CardContainer>
  );
}
