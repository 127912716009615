import styled from "styled-components";

type PropsModal = {
  visible?: boolean;
};
export const Modal = styled.div<PropsModal>`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(4px);
  z-index: 10;
  opacity: 1;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;

export const ModalContainer = styled.div`
  position: relative;
  margin-top: 1.1rem;
  margin-bottom: 1.1rem;
  padding: 20px;
  max-width: 600px;
  min-width: 400px;
  max-height: 70%;
  z-index: 2;
  opacity: 1;
  border-radius: 10px;
  background: #fff;

  @media (min-height: 1080px) {
    min-height: 400px;
  }
  
  overflow: auto;

  @media (max-width: 600px) {
    max-width: 100%;
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    margin-top: 0;
    border-radius: 0;
  }
`;

export const BtnClose = styled.button`
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  border: none;
  background: none;
  padding: 10px;
`;

export const ModalContent = styled.div`
  margin-top: 0.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
