import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { FiArrowLeft, FiX } from 'react-icons/fi'
import 'rc-slider/assets/index.css'
import { Formik } from 'formik'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { AdminContainer, Input, PageSubTitle, PageTitle, Label, EnterButton, Error, InputGroup, TextArea, Span } from '../globalStyles'
import { LogoImg, Header, IconContainer, ReturnText, Form, KeyWord, KeysContainer, KeyWordText } from './styles'
import logo from '../../../images/logo-dark.png'
import { api } from '../../../services/Admin/api'
import { RoutineRegisterSchema } from '../../../schemas/routineSchemas'
import { IRoutine } from '../../../interfaces/routine'


export function RoutineRegister() {
  const [keyWord, setKeyWord] = useState<string>('')
  const [keyWords, setKeyWords] = useState<string[]>([])

  const history = useHistory()
  const location = useLocation() 

  function handleNavigateToInventary() {
    history.push('/admin/inventario')
    
  }

  function handleKeyPress(e: any){
    if(e.code === 'Enter'){
      SetNewKeyWordToArray(keyWord)
    }
  }

  function SetNewKeyWordToArray(keyWord: string){
    setKeyWords( keyWords => [...keyWords, keyWord] )
    setKeyWord('')
  }

  function handleRemoveKeyWords(keyWord: string){
    const filteredArray = keyWords.filter( item => {
      return item !== keyWord
    })
    setKeyWords(filteredArray)
  }

  async function handleRegisterNewRoutine(data: IRoutine ) {
    try {
      await api.post('/routines/create', data)
      toast.success('Rotina cadastrado com sucesso!')
      setTimeout(() => history.push('/admin/inventario'), 4000)
    } catch (error: any) {
      if (error.response.data) {
        toast.warning(error.response.data.error)
        if (error.response.status === 401) setTimeout(() => history.push('/admin/login'), 3000)
      }
    }
  }

  return (
    <AdminContainer>
      <ToastContainer />
      <Header>
        <LogoImg src={logo} />
        <IconContainer>
          <FiArrowLeft onClick={handleNavigateToInventary} color="var(--blue)" size={24} />
          <ReturnText>Voltar para o inventário</ReturnText>
        </IconContainer>
      </Header>
      <Formik
        initialValues={
          { 
            name:'', 
            description:'', 
            notes: '', 
            keyWords: [] 
          }}
        onSubmit={values => handleRegisterNewRoutine({...values, keyWords})}
        validationSchema={ RoutineRegisterSchema}
      >
        {({ errors, touched, handleSubmit, handleChange }) => (
          <Form>
            <PageTitle>{ location.pathname === '/admin/rotina/criar'? 'Cadastro': 'Atualização'} de rotina</PageTitle>
            <PageSubTitle>Dados</PageSubTitle>

              <InputGroup>
                <Label htmlFor="name" >Nome:</Label>
                < Input onChange={handleChange('name')} name="name" width="100%" />
                {(errors.name && touched.name) && (<Error>{errors.name}</Error>)}
              </InputGroup>


              <InputGroup>
                <Label htmlFor="description" >Descrição:</Label>
                < TextArea onChange={handleChange('description')}  name="description" />
                {(errors.description && touched.description) && (<Error>{errors.description}</Error>)}
              </InputGroup>

              <InputGroup>
                <Label htmlFor="notes" >Observações:</Label>
                < TextArea onChange={handleChange('notes')}  name="notes" />
                {(errors.notes && touched.notes) && (<Error>{errors.keyWords}</Error>)}
              </InputGroup>

              <InputGroup>
                <Label htmlFor="keyWords" >Palavras-chave:</Label>
                < Input
                  value={keyWord} 
                  onChange={ e=> setKeyWord(e.target.value) } 
                  onKeyPress={handleKeyPress} name="KeyWords" 
                  width="100%" />
                  <Span>Aperte Enter após digitar cada palavra</Span>
               <KeysContainer>
                {keyWords.map((keyWord, i) => (
                    <KeyWord key={keyWord+i} > 
                    <KeyWordText>#{keyWord}</KeyWordText>
                    <FiX onClick={()=> handleRemoveKeyWords(keyWord) }  color="var(--white)" size={24} />
                    </KeyWord>
                  ))}
               </KeysContainer>
                {(errors.keyWords && touched.keyWords) && (<Error>{errors.keyWords}</Error>)}
              </InputGroup>
              
            <EnterButton type="button" onClick={handleSubmit as () => void} >Cadastrar</EnterButton>

          </Form>
        )}
      </Formik>
    </AdminContainer>
  )
}