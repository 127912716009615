import React from 'react'
import { useHistory, useParams } from 'react-router'
import { toast, ToastContainer } from 'react-toastify'

import { api } from '../../../services/Admin/api'

import { AdminContainer, PageSubTitle, PageTitle, EnterButton, Error } from '../globalStyles'
import { LogoImg, Form, Input } from './styles'

import logo from '../../../images/logo-dark.png'
import { Field, Formik } from 'formik'
import { resetPassowrdSchema } from '../../../schemas/userSchemas'
import { useAuth } from '../../../hooks/useAuth'

interface Params{
  token: string,
  email: string
}

export default function ResetPassword() {

  const history = useHistory()
  const { email,token } = useParams() as Params
  const { storageUser } = useAuth()

  async function handleResetPassword(password: string){
    try {
      const response = await api.post(`/users/reset_password/${token}`, {email, password})
      storageUser(response.data.user)
      history.push('/admin/inventario')
    } catch (error: any) {
      if(error.response.data){
        toast.warning(error.response.data.error)
      }
    }
  }

  return (
    <AdminContainer>
      <ToastContainer />
      <LogoImg src={logo} alt="TOTVS Unidade Brasil Central Agro" />
      <PageTitle>Sessão Administrativa</PageTitle>
      <PageSubTitle>Digite sua nova senha e confirme</PageSubTitle>
      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        validationSchema={resetPassowrdSchema}
        onSubmit={values => handleResetPassword(values.password)}
      >
        {({
          handleSubmit,
          errors,
          touched
        }) => (
          <Form>
            <Field type="password" as={Input} name="password" placeholder="Senha" />
            {(errors.password && touched.password) && (<Error>{errors.password}</Error>)}

            <Field type="password" as={Input} name="confirmPassword" placeholder="Confirmar senha" />
            {(errors.confirmPassword && touched.confirmPassword) && (<Error>{errors.confirmPassword}</Error>)}

            <EnterButton type="button" onClick={handleSubmit as () => void} >Entrar</EnterButton>
          </Form>
        )}
      </Formik>
    </AdminContainer>
  )
}