import './styles.css';
export const Logo = () => {
    return (
        <div className="logo">
            <h1>
                TOTVS <br />
                <span>AGRO</span>
                <br /> DAY
            </h1>
        </div>
    );
};
