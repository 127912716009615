import styled from 'styled-components';

export const AuthorImage = styled.div`
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
    }
`;

export const CardContainer = styled.div`
    width: 364px;
    min-height: 585px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 23px;
    margin: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #e6e6e6;
    transition: all 0.3s ease-in-out;
    transform: scale(0.95);

    &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        transform: translateY(-5px);
    }

    @media (max-width: 600px) {
        max-width: 100%;
        min-width: 100%;
        min-height: 100%;
    }
`;

export const CardTitle = styled.h3`
    color: var(--grey);
    margin: 15px 0;
    text-align: left;
`;

export const CardText = styled.p`
    color: var(--color-gray-dark);
    text-align: left;
`;

export const Social = styled.div`
    display: flex;
    justify-content: center;
    align-items: right;
    margin-top: 20px;

    img {
        width: 30px;
    }
`;
