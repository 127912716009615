import styled from 'styled-components';

export const Requirement = styled.div`
    display:flex;
    flex-direction: row;
    background: var(--blue);
    justify-content: space-between;
    padding: 5px;
    border-radius: 5px;
    color: var(--white);
    width: 450px;
    max-width: 80vw;
`