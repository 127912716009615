import styled from 'styled-components';

export const ContainerDetails = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
    height: auto;
    @media (max-width: 768px) {
        justify-content: center;
    }
`;

export const ImageIcon = styled.img`
    width: 30px;
    margin-right: 7px;
`;

export const Link = styled.a`
    transition: all 0.3s ease-in-out;
    color: var(--blue);
    &:hover {
        color: var(--orange);
    }
`;

export const EventDetailsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    div {
        margin-bottom: -10px
    }
    @media (max-width: 1255px) {
        flex-direction: column;
        align-items: center;
    }
`;

