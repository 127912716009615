import styled, { css } from 'styled-components';

export const Main = styled.main`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center !important;
    background: var(--blue);
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 1255px) {
        height: auto;
    }
`;

export const Header = styled.header`
    position: absolute;
    top: 0;
`;

export const ImageLogo = styled.img`
    padding: 10px;
    width: 150px;

    @media (max-width: 351px) {
        width: 100%;
    }
`;

export const UpBars = styled.img`
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;

    @media (max-width: 1280px) {
        position: relative;
        align-self: flex-start;
    }

    @media (max-width: 450px) {
        display: none;
    }
`;

export const DownBars = styled.img`
    position: absolute;
    bottom: 0;
    right: 0;

    @media (max-width: 1280px) {
        position: relative;
        align-self: flex-end;
    }

    @media (max-width: 450px) {
        display: none;
    }
`;

export const Section = styled.section`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 70%;
    margin-top: 100px;

    @media (max-width: 1255px) {
        width: 100%;
        flex-direction: column;
    }
`;

export const SectionContentText = styled.section`
    width: 50%;

    @media (max-width: 1150px) {
        width: 60%;
    }
    @media (max-width: 768px) {
        width: 80%;
    }
    @media (max-width: 425px) {
        width: 90%;
    }
`;

export const SectionContentMedia = styled.section`
    width: 45%;
    display: flex;
    justify-content: center;

    @media (max-width: 1255px) {
        margin: 30px 0;
        width: auto;
    }
    @media (max-width: 768px) {
        width: 95%;
    }
    @media (max-width: 500px) {
        width: 100%;
    }
`;

export const Title = styled.h1`
    color: ${(props) => (props.color ? '#0adbe3' : 'white')};
    font-size: ${(props) => (props.color ? '32px' : '24px')};
`;

export const SubTitle = styled.h2`
    color: #102136;
    text-align: center;
    font-size: 24px;
    margin: 30px 10px;
`;

export const Paragraph = styled.p`
    margin: ${(props) => (props.color ? '20px 0' : '0')};
    color: ${(props) => (props.color ? '#D3D3D3' : '#888')};
    font-size: 17px;
    line-height: 23px;
`;

export const Iframe = styled.iframe`
    border: none;
    border-radius: 10px;
    ${(props) =>
        !props.width &&
        css`
            width: 100%;
            margin: 20px 0;
            border-radius: 10px !important;
        `};
    @media (max-width: 500px) {
        border-radius: 0;
    }

    @media (max-width: 320px) {
        max-width: 100%;
    }
`;
type PropsContainerBtn = {
    align?: boolean;
};
export const ContainerBtn = styled.div<PropsContainerBtn>`
    width: 100%;
    height: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: ${(props) => (props.align ? 'flex-start' : 'center')};
    align-items: center;

    @media (max-width: 1255px) {
        justify-content: center;
    }
`;

export const Scroll = styled.img`
    width: 150px;
    height: fit-content;
`;

export const ContainerCardsBenefits = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    margin-top: 50px;

    ${Section} {
        justify-content: space-between;
        margin-top: 0;
        
    }

    @media (max-width: 1425px) {
        align-items: center;

        ${Section} {
            width: 100%;
            justify-content: space-around;
        }
    }
`;

export const SectionDemonstrationContainer = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
`;

export const SectionDemonstration = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 70%;

    @media (max-width: 768px) {
        width: 95%;
    }
`;

export const Overlay = styled.div`
    display: ${(props) => (props.hidden ? 'none' : 'flex')};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    z-index: 5;
    position: fixed;
    width: 100vw;
    min-height: 100vh;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.89);

    @media (max-width: 500px) {
        padding: 0px;
    }
`;
