import React, { useEffect, useState } from 'react'
import { Image, Main, Text, Title } from '../localStyles'
import logo from '../../../images/logo-totvs.svg'
import notFound from '../../../images/noData.svg'
import { AddJobContainer, GridContainer, MailLink, NotFoundImg } from './styles'
import { JobCard } from '../components/JobsCard'
import { JobsModalDetails } from '../components/JobsModalDetails'
import { baseApi as api } from '../../../services/baseApi'
import { toast, ToastContainer} from 'react-toastify'
import { Job } from '../../../interfaces/job'
import { useHistory, useLocation } from 'react-router-dom'
import { FiPlus } from 'react-icons/fi'
import { JobsModalAuth } from '../components/JobsModalAuth'
import { ConfirmDeleteModal } from '../components/ConfirmDeleteModal'


export function JobsList() {
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false)
  const [isAuthModalVisible, setIsAuthModalVisible] = useState(false)
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const [jobs, setJobs] = useState<Job[]>()
  const [selectedJob, setSelectedJob] = useState<Job>()
  const [jobColor, setJobColor] = useState('')
  const location = useLocation()
  const history = useHistory()

  function handleNavigateToRegister() {
    history.push('/vagas/cadastro')
  }

  useEffect(() => {

    async function getJobs() {
      try {
        const response = await api.get('/jobs/listen')
        setJobs(response.data)
      } catch (error: any) {
        if (error.response && error.response.data) toast.error(error.response.data.error)
      }
    }

    async function getAvailableJobs(){
      try {
        const response = await api.get('/jobs/available')
        setJobs(response.data)
      } catch (error: any) {
        if (error.response && error.response.data) toast.error(error.response.data.error)
      }
    }

    location.pathname === '/vagas/admin' ? getJobs() : getAvailableJobs()

    if (location.pathname === '/vagas/admin') {
      const token = localStorage.getItem("jobs_token")
      if (!token) {
        setIsAuthModalVisible(true)
      } 
    }

  }, [location, isConfirmModalVisible])

  return (
    <Main>
      <Image src={logo} alt="TOTVS Brasil Central Agro" />
      <Title>Selecione uma vaga para ver mais detalhes</Title>
      <Text className="large-text" >Envie seu currículo para <MailLink href={`mailto:rh@tbcagro.com.br?Subject=Envio de curriculo`} >rh@tbcagro.com.br</MailLink></Text>
      {location.pathname === '/vagas/admin' && (
        <AddJobContainer onClick={handleNavigateToRegister}>
          <Text className="medium-text">Adicionar nova vaga</Text>
          <FiPlus color="var(--blue)" size={24} />
        </AddJobContainer>
      )}
      {jobs && jobs.length>0? (
        <GridContainer>
        {jobs?.map((job, index) => (
          <JobCard 
          color={ index % 3 === 1? 'var(--blue)' : index % 3 === 0? 'var(--orange)' : "var(--lilac)" }  
          key={job._id} openDeleteModal={()=> setIsConfirmModalVisible(true)  } 
          onClick={() => {setSelectedJob(job); setJobColor(index % 3 === 1? 'var(--blue)' : index % 3 === 0? 'var(--orange)' : "var(--lilac)" ) }} 
          execute={() => setIsDetailsModalVisible(true)} 
          job={job} 

          />
        ))}
      </GridContainer>
      ): (
        <>
        <NotFoundImg src={notFound} />
        <Text className="large-text" >Nenhuma vaga cadastrada até o momento</Text>
        </>
      )}
      {selectedJob && <JobsModalDetails color={jobColor} job={selectedJob} isVisible={isDetailsModalVisible} setIsVisible={setIsDetailsModalVisible} />}
      <JobsModalAuth isVisible={isAuthModalVisible} setIsVisible={setIsAuthModalVisible} />
      <ConfirmDeleteModal onError={ () => setIsAuthModalVisible(true) } jobId={selectedJob?._id as string } isVisible={isConfirmModalVisible} setIsVisible={setIsConfirmModalVisible} />
      <ToastContainer />
    </Main>
  )
}