import styled from "styled-components";

export const LogoImg = styled.img`
    width: 560px;
    max-width: 100%;
    
`

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    max-width: 100%;
`
export const Input = styled.input`
    background: var(--white);
    outline: none;
    border:0;
    border-radius: 5px;
    width: 450px;
    max-width: 100%;
    height: 65px;
    padding: 20px;

    font-family: Nunito, sans-serif;
    font-weight: normal;
    font-size: 16.4175px;
    line-height: 22px;
    color: var(--grey-lighter);
    margin-top: 30px;
`