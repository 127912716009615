import styled from "styled-components";

export const ModalContainer = styled.div`
  width: 550px;
  max-width: 100%;
  max-height: 90vh;
  background: var(--white);
  padding: 2.5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
  transition: 0.2s;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: justify;

  input{
    background: var(--lightgrey);
  }

  @media(max-width: 500px) {

    min-width: 100vw;
    min-height: 100vh;

    align-items: center;
    justify-content: center;
    border-radius: 0;
  }
`;
