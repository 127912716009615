import React from 'react'

import { Hr, HrContainer } from './styles'

function ElementDivider({...rest}) {
    return (
        <HrContainer {...rest}>
            <Hr />
        </HrContainer>
    )
}

export default ElementDivider
