import styled from 'styled-components'

export const FooterContainer = styled.footer`
    width: 100%;
    height: 200px;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    background: ${props => props.color? props.color : 'var(--color-background)'};
`

export const SectionMediasContainer = styled.section`
    display: flex;
    flex-direction: column;
`

export const IconsMediasContainer = styled.section`
    display: flex;
    justify-content: center;
`

export const TitleSocialMedias = styled.h4`
    color: #fff;
`
