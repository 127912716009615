import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import {
  AdminContainer,
  FlexRowDiv,
  IconCircle,
  PageSubTitle,
  Header,
  IconContainer,
  IconGroup,
  IconText,
  LogoImg,
  Main,
  Menu,
  Table,
  TableHeader,
  TableHeaderText,
  TableRow,
  TopBar,
  Text,
} from "../globalStyles";
import logo from "../../../images/logo-dark.png";

import {
  FiPower,
  FiUsers,
  FiHome,
  FiPlus,
  FiTrash,
  FiShield,
  FiShieldOff,
  FiEdit2,
} from "react-icons/fi";
import { SearchBar } from "../../../components/Admin/SearchBar";
import api from "../../../services/Admin/api";
import { useHistory } from "react-router";
import { ConfirmModal } from "../../../components/Admin/modals/ConfirmModal";
import { useAuth } from "../../../hooks/useAuth";
import { IUser } from "../../../interfaces/User";
import { useWindowDimensions } from "../../../hooks/useWIndowsDimensions";

export function UsersManager() {
  const [users, setUsers] = useState<IUser[]>();
  const [isDeleteUserModalVisible, setIsDeleteUserModalVisible] =
    useState(false);
  const [isLogOutModalVisible, setIsLogOutModalVisible] = useState(false);
  const [isBlockUserModalVisible, setIsBlockUserModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [userState, setUserState] = useState<boolean>();
  const [loadData, setLoadData] = useState(true);
  const history = useHistory();
  const { width } = useWindowDimensions();
  const { clearUserFromStorage, loadUserFromStorage, user } = useAuth();
  const loggedUser = user;

  function handleNavigateToCreateUser() {
    history.push("/admin/cadastro");
  }

  async function loadUsersDataFromApi() {
    try {
      const response = await api.get("/users/listen");
      setUsers(response.data);
      setLoadData(false);
    } catch (error: any) {
      if (error.response.data) {
        toast.warning(error.response.data.error);
        if (error.response.status === 401) history.push("/admin/login");
      } else {
        toast.error("Ops! Não foi possivel comunicar com o servidor");
      }
    }
  }

  async function handleDeleteUser(id: string) {
    try {
      await api.delete(`/users/delete/${id}`);
      setIsDeleteUserModalVisible(false);
      if (id === loggedUser?._id) {
        clearUserFromStorage();
      }
      setLoadData(true);
      document.location.reload();
      toast.success("Usuário deletado com sucesso!");
    } catch (error: any) {
      toast.warning(error.response.data.error);
      if (error.response.status === 401) history.push("/admin/login");
    }
  }

  function handleUpdateUser(id: string) {
    history.push(`/admin/usuario/atualizar/${id}`);
  }

  async function handleChangeBlockUserState(state: boolean) {
    try {
      await api.put(`/users/update/${selectedUser}`, { isBlocked: !state });
      setIsBlockUserModalVisible(false);
      if (selectedUser === loggedUser?._id) {
        clearUserFromStorage();
      }
      setLoadData(true);
    } catch (error: any) {
      if (error.response.data) {
        toast.warning(error.response.data.error);
        if (error.response.status === 401)
          setTimeout(() => history.push("/admin/login"), 3000);
      }
    }
  }

  function handleLogout() {
    clearUserFromStorage();
    history.push("/admin/login");
  }

  function handleDeleteClick(id: string) {
    setSelectedUser(id);
    setIsDeleteUserModalVisible(true);
  }

  function handleBlockClick(id: string, state: boolean) {
    setSelectedUser(id);
    setUserState(state);
    setIsBlockUserModalVisible(true);
  }

  useEffect(() => {
    loadData && loadUsersDataFromApi();
    loadUserFromStorage();
    if (user && !user?.isAdmin) {
      toast.error("Você não tem permissão para acessar essa pagina");
      history.push("/admin/inventario");
    }
  }, [history, loadData]);

  return (
    <AdminContainer>
      <ConfirmModal
        title="Tem certeza que deseja excluir esse item?"
        isVisible={isDeleteUserModalVisible}
        setIsVisible={setIsDeleteUserModalVisible}
        handleClose={() => handleDeleteUser(selectedUser)}
      />

      <ConfirmModal
        title="Tem certeza que deseja sair dessa conta?"
        isVisible={isLogOutModalVisible}
        setIsVisible={setIsLogOutModalVisible}
        handleClose={handleLogout}
      />

      <ConfirmModal
        title={
          userState !== true
            ? `Tem certeza que deseja bloquear ${
                selectedUser === loggedUser?._id ? "sua" : "essa"
              } conta?`
            : "Tem certeza que deseja desbloquear essa conta?"
        }
        description={
          userState !== true
            ? "Usuário bloqueados ficarão sem acesso as funções da aplicação até serem desbloqueados novamente."
            : "Se desbloqueado, o usuário terá acesso a todos os recursos que tinha antes do bloqueio."
        }
        isVisible={isBlockUserModalVisible}
        setIsVisible={setIsBlockUserModalVisible}
        handleClose={() => handleChangeBlockUserState(userState as boolean)}
      />

      <ToastContainer />
      <Header>
        <LogoImg src={logo} />
        {width > 400 && (
          <Menu>
            <IconGroup onClick={() => setIsLogOutModalVisible(true)}>
              <FiPower color="var(--red)" size={35} />
              <IconText color="var(--red)">Sair</IconText>
            </IconGroup>
            <IconGroup onClick={() => history.push("/admin/usuarios")}>
              <FiUsers color="var(--blue)" size={35} />
              <IconText color="var(--blue)">Usuários</IconText>
            </IconGroup>
            <IconGroup onClick={() => history.push("/admin/inventario")}>
              <FiHome color="var(--grey-lighter)" size={35} />
              <IconText color="var(--grey-lighter)">Home</IconText>
            </IconGroup>
          </Menu>
        )}
      </Header>

      <Main>
        <PageSubTitle>Usuários</PageSubTitle>
        <TopBar>
          <SearchBar type="users" setData={setUsers as any} />
          <FlexRowDiv>
            <Text>Adicionar novo usuário</Text>
            <IconContainer>
              <FiPlus
                onClick={handleNavigateToCreateUser}
                color="var(--white)"
                size={24}
              />
            </IconContainer>
          </FlexRowDiv>
        </TopBar>

        <Table>
          <TableHeader>
            <TableHeaderText>Usuários</TableHeaderText>
            {width > 900 && (
              <>
                <TableHeaderText>E-mail</TableHeaderText>
                <TableHeaderText>Administrador</TableHeaderText>
                <TableHeaderText>Ações</TableHeaderText>
              </>
            )}
          </TableHeader>
          {users?.map((user) => (
            <TableRow key={user._id}>
              {width > 900 ? (
                <>
                  <Text>
                    {user.name} {user._id === loggedUser?._id && "(Você)"}{" "}
                  </Text>
                  <Text>{user.email}</Text>
                  <Text>{user.isAdmin ? "Sim" : "Não"}</Text>
                </>
              ) : (
                <>
                  <Text>
                    <strong>Nome:</strong> {user?.name}
                  </Text>
                  <Text>
                    <strong>E-mail:</strong> {user?.email}
                  </Text>
                  <Text>
                    <strong>CPF:</strong> {user?.cpf}
                  </Text>
                  <Text>
                    <strong>Ações</strong>
                  </Text>
                </>
              )}
              <Text>
                <FlexRowDiv>
                  <IconCircle
                    onClick={
                      user.isMaster && !loggedUser?.isMaster
                        ? () => {}
                        : () => handleUpdateUser(user._id as string)
                    }
                    color={
                      user.isMaster && !loggedUser?.isMaster
                        ? "var(--grey-lighter)"
                        : "var(--aqua)"
                    }
                  >
                    <FiEdit2 color="var(--white)" size={24} />
                  </IconCircle>

                  <IconCircle
                    onClick={
                      user.isMaster || loggedUser?._id === user._id
                        ? () => {}
                        : () => handleDeleteClick(user._id as string)
                    }
                    color={
                      user.isMaster || loggedUser?._id === user._id
                        ? "var(--grey-lighter)"
                        : "var(--red)"
                    }
                  >
                    <FiTrash color="var(--white)" size={24} />
                  </IconCircle>

                  {user.isBlocked ? (
                    <IconCircle
                      onClick={() =>
                        handleBlockClick(
                          user._id as string,
                          user.isBlocked as boolean
                        )
                      }
                      color="var(--grey)"
                    >
                      <FiShieldOff color="var(--white)" size={24} />
                    </IconCircle>
                  ) : (
                    <IconCircle
                      onClick={
                        user.isMaster || loggedUser?._id === user._id
                          ? () => {}
                          : () =>
                              handleBlockClick(
                                user._id as string,
                                user.isBlocked as boolean
                              )
                      }
                      color={
                        user.isMaster || loggedUser?._id === user._id
                          ? "var(--grey-lighter)"
                          : "var(--grey)"
                      }
                    >
                      <FiShield color="var(--white)" size={24} />
                    </IconCircle>
                  )}
                </FlexRowDiv>
              </Text>
            </TableRow>
          ))}
        </Table>
      </Main>
      {width <= 400 && (
        <Menu>
          <IconGroup onClick={() => setIsLogOutModalVisible(true)}>
            <FiPower color="var(--red)" size={35} />
            <IconText color="var(--red)">Sair</IconText>
          </IconGroup>
          <IconGroup onClick={() => history.push("/admin/usuarios")}>
            <FiUsers color="var(--blue)" size={35} />
            <IconText color="var(--blue)">Usuários</IconText>
          </IconGroup>
          <IconGroup onClick={() => history.push("/admin/inventario")}>
            <FiHome color="var(--grey-lighter)" size={35} />
            <IconText color="var(--grey-lighter)">Home</IconText>
          </IconGroup>
        </Menu>
      )}
    </AdminContainer>
  );
}
