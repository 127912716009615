import React, { useEffect, useState } from "react";

import Button from "../../components/Button";
import CardBenefits from "../../components/Card";
import Footer from "../../components/Footer";
import ElementDivider from "../../components/ElementDivider";
import { ModalView } from "../../components/ModalView";
import FormComponent from "../../components/FormComponent";
import EventDetails from "../../components/EventDetails";

import logoTotvs from "../../images/logo.png";
import RocketIcon from "../../images/icons-totvs/rocket-icon.png";
import TargetIcon from "../../images/icons-totvs/alvo-icon.png";
import StarIcon from "../../images/icons-totvs/star-icon.png";
import AuthorImg from "../../images/AgriculturalCost/author.jpeg";
import downBarsImg from '../../images/downBars.png'
import upBarsImg from '../../images/upBars.png';
import LinkedinIcon from "../../images/bioenergia/linkedin.svg";
import {FiCalendar, FiCheckCircle, FiChevronsDown} from 'react-icons/fi'
import eventData from  '../../utils/EventData.json'

import {
  Main,
  Header,
  ImageLogo,
  Section,
  Title,
  SubTitle,
  Paragraph,
  SectionContentText,
  SectionContentMedia,
  Iframe,
  ContainerBtn,
  ContainerCardsBenefits,
  UpBars,
  DownBars
} from "../../styles";

import {
  AuthorContainer,
  AuthorImage,
  AuthorLinkedin,
  AuthorTitle,
  SectionAuthorContainer,
} from "./styles";


const AgriculturalCost: React.FC = () => {
  const pageContent = eventData

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    localStorage.setItem("page_identifier", pageContent.laharIdentifier);
    document.title = "Evento Custo Agrícola"

  }, [pageContent.laharIdentifier]);

  function handleChangeOpenModal() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <ModalView closeModal={handleChangeOpenModal} isOpen={isOpen}>
        <FormComponent />
      </ModalView>
      <Main>
        <UpBars src={upBarsImg} />
        <Header>
          <ImageLogo src={logoTotvs} />
        </Header>
        <Section>
          <SectionContentText>
            <Title>{pageContent?.title}</Title>
            <Title>{pageContent?.subTitle}</Title>
            <Paragraph color="true">{pageContent?.description}</Paragraph>
            <EventDetails text="25/11, às 19h" icon={<FiCalendar color="white" size={30}/>} />
            <EventDetails text="100% online e gratuito" icon={<FiCheckCircle color="white" size={30} />}/>
            <ContainerBtn align>
              <Button 
              color="white" 
              background="true" 
              content={pageContent?.CTA} 
              cta={handleChangeOpenModal} />
            </ContainerBtn>
          </SectionContentText>
          <SectionContentMedia>
            <Iframe
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${pageContent?.youtubeEmbed}`}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></Iframe>
          </SectionContentMedia>
        </Section>
        <FiChevronsDown color="white" size={100} />
        <DownBars src={downBarsImg} />
      </Main>
      <SubTitle>{pageContent?.titleBenefits}</SubTitle>

      <ContainerCardsBenefits>
        <CardBenefits
          image={TargetIcon}
          title="Tomar decisão no menor tempo possível com base em fatos"
          text="Descubra como obter visibilidade do custo de produção em tempo real baseado no consumo de recursos por atividade, permitindo a tomada de decisão instantânea conforme os fatos se apresentam no dia a dia da unidade de produção agrícola.
          "
        />
        <CardBenefits
          image={RocketIcon}
          title="Melhorar as margens de ganho e rentabilidade"
          text="Entenda como o conhecimento da formação do custo da lavoura o ajudará a ter maior segurança no momento da negociação no mercado de commodities, o que resulta em uma propriedade com maior rentabilidade."
        />
        <CardBenefits
          image={StarIcon}
          title="Busca por melhores taxas no custeio de produção"
          text="Avalie a evolução dos resultados obtidos a cada ano safra em cada cultura cultivada e obtenção do custo consolidado para formação de demonstrações financeiras aceitas por Auditorias Independentes e Entidades Financeiras."
        />
      </ContainerCardsBenefits>
      <ElementDivider id="demo" />
      <SubTitle>{pageContent?.titleAuthor}</SubTitle>
      <SectionAuthorContainer>
        <AuthorContainer>
          <AuthorImage src={AuthorImg} />
          <SectionContentText>
            <AuthorTitle>
              {pageContent?.author}
              <AuthorLinkedin
                href={pageContent?.authorLinkedin}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={LinkedinIcon} alt="Linkedin" />
              </AuthorLinkedin>
            </AuthorTitle>
            <Paragraph>{pageContent?.authorResume}</Paragraph>
          </SectionContentText>
        </AuthorContainer>
      </SectionAuthorContainer>
      <ContainerBtn>
        <Button 
        color='white' 
        content={pageContent?.CTADemo} 
        cta={handleChangeOpenModal} />
      </ContainerBtn>
      <Footer />
    </>
  );
};

export default AgriculturalCost;
