import * as Yup from "yup";

export const schemaFirstStep = Yup.object().shape({
  name: Yup.string().required("O campo Nome é obrigatório"),
  email: Yup.string()
    .email("Insira um email válido")
    .required("O campo Email é obrigatório"),
  cpf: Yup.string()
    .matches(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/, "Insira um CPF válido")
    .required("O campo CPF é obrigatório"),
  phone: Yup.string()
    .min(10, "Insira um número válido")
    .required("O campo Celular é obrigatório"),
  presencial: Yup.string().required("Você precisa escolher uma opção"),
});

export const schemaSecondStep = Yup.object().shape({
  occupation: Yup.string().required("O campo Ocupação é obrigatório"),
  port: Yup.string().required("Este campo é obrigatório"),
});
