import React from 'react'

import { Formik, Form, Field } from "formik";
import { schemaSecondStep } from '../../../schemas';

import { Button, Error, FieldGroup, Label, Select } from '../styles';
import { ContainerBtn } from '../../../styles';

export interface ISecondStep {
    occupation: string;
    port: string;
}

interface Props {
    registerLead: ( data: ISecondStep, step: number) => Promise<void>;
}

const SecondStepForm: React.FC<Props> = ({ registerLead }) => {
    const initialValues: ISecondStep = { occupation: '', port: ''}

    const occupation: string[] = [
        "Selecione uma opção",
        "Produtor Rural",
        "Cooperativa Agropecuária",
        "Manufatura para o Agro",
        "Revenda",
        "Armazéns Cereais",
        "Trading",
        "Agroindústria",
        "Prestadores de Serviços",
        "Outros"
    ]

    const port: string[] = [
        "Selecione",
        "0 a 9 pessoas",
        "10 a 49 pessoas",
        "50 a 249 pessoas",
        "250 pessoas ou mais"
    ]

    return (
        <Formik
            validationSchema={schemaSecondStep}
            initialValues={initialValues}
            onSubmit={(values, actions) => {
                registerLead(values, 2)
                actions.setSubmitting(false);
            }}
       >
           {({ errors, touched }) => (
            <Form>
                <br />
                <FieldGroup>
                    <Label htmlFor="occupation">Ocupação</Label>
                    <Field as={Select} name="occupation">
                        {occupation.map((occupation, key) => {
                            return (
                                <option key={key} value={occupation}>{occupation}</option>

                            )
                        })}
                    </Field>
                    {errors.occupation && touched.occupation && ( <Error> {errors.occupation} </Error> )}
                </FieldGroup>
                <FieldGroup>
                    <Label htmlFor="port">Número de colaboradores</Label>
                    <Field as={Select} name="port">
                    {port.map((port, key) => {
                            return (
                                <option key={key} value={port}>{port}</option>

                            )
                        })}
                    </Field>
                    {errors.port && touched.port && ( <Error> {errors.port} </Error> )}
                </FieldGroup>
                <ContainerBtn>
                    <Button color='white' type="submit">Quero me inscrever</Button>
                </ContainerBtn>
            </Form>
           )}
       </Formik>
    )
}

export default SecondStepForm
