import { Field, Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { LeadDataStepTwo } from '../../interfaces/EbookLeadData';
import { handleLaharIntegration } from '../../services/ebookApi';
import { schemaEbookStepTwo } from '../../schemas/ebook'
import revenuesArray from '../../utils/revenues.json'

interface StepTwoProps{
    notify: () => React.ReactText;
    ebook: string;
}

export function StepTwo(props: StepTwoProps){
    return(
        <Formik
            validationSchema={schemaEbookStepTwo}
            initialValues={{
            enterprise: "",
            revenue:""
            }}
            onSubmit={async (
            values: LeadDataStepTwo,
            { setSubmitting }: FormikHelpers<LeadDataStepTwo>
            ) => {
            await handleLaharIntegration(props.ebook, null, values)
            props.notify()
            setSubmitting(false)
            }}
        >
            {({ errors }) => (
            <Form className="form">
                <div className="field-group">
                <label htmlFor="enterprise">Nome da empresa</label>
                <Field id="enterprise" name="enterprise" placeholder="Nome da empresa" />
                {errors.enterprise && ( <p className="error"> {errors.enterprise} </p> )}
                </div>

                <div className="field-group">
                <label htmlFor="revenue">Faturamento</label>
                <Field as="select" id="revenue" name="revenue">
                    <option value="">Selecione</option>
                    
                    {revenuesArray.map(({ value, revenue }) => {
                    return (
                        <option key={value} value={value}>
                        {revenue}
                        </option>
                    );
                    })}

                </Field>
                </div>

                <button className="btn-form" type="submit">Finalizar</button>
            </Form>
            )}
        </Formik>
)
}