import styled from "styled-components";

export const Header = styled.header`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 3;

    @media(max-width:700px){
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span, svg{
        display: none;
      }
    }
`

export const LogoImg = styled.img`
    height: 80px;
    max-width: 100%;
    align-self: flex-start;
    `

export const IconContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    svg{
        font-weight: 700;

        &:hover{
            cursor: pointer;
        }
    }
`

export const ReturnText = styled.span`
    font-family: Nunito, sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
`

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    max-width: 100%;
    background: var(--white);
    padding: 64px;
    border-radius: 5px;

    h2{
        align-self:flex-start;
    }

    button{
        align-self: center;
    }

    @media (max-width: 780px){
        input{
          width: 100%;
        }
    }

    @media(max-width: 700px){
      min-width: 100vw;
      min-height: 100vh;
      padding: 10%;
      position: absolute;
      top:0%;

      background: var(--lightgrey);

      h1{
        margin-top: 100px;
        margin-bottom: 10px;
      }

      input{
        background: var(--white);
      }
      
    }
`

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    input{
        max-width: calc(100% - 50px);
    }
`

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;


    @media (max-width: 780px){
        grid-template-columns: 1fr;
    }

`

