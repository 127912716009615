import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
   *{
       margin: 0;
       padding: 0;
       outline: 0;
       box-sizing: border-box;
       font-family: 'Lato', sans-serif;
    }


   #root{
        /* BUTTONS STYLES */
        --btn-primary: #0ADBE3;
        --btn-primary-font: #FFF;

        /* INPUT STYLES*/

        --input-background: #F0F0F5;

        --color-gradient: linear-gradient(90deg, #97fcff, #0ADBE3);
        /* --color-gradient: #0ADBE3; */

        --color-primary: #0ADBE3;
        --color-background: #363636;

        --color-gray: rgb(211, 211, 211);
        --color-gray-dark: rgb(136, 136, 136);

        --white: #ffffff;
        --grey:	#363636;
        --grey-lighter: #807870;
        --blue:	#0897e9;
        --blue-darker: #0897EA;
        --red: #D92828;
        /*SECONDARY*/
        --yellow: #0ADBE3;
        --orange: #FEAC0E;
        --lightgrey: #eeeeee;
        /*COMPLEMENTARY*/
        --aqua:	#0adbe3;
        --lilac: #6b48ff;
        --green: #17D178;
   }

   ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-thumb {
        background: #807870;
        height: 300px !important;
        border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background:#363636;
    }
`;
