import * as Yup from "yup";

export const schemaEbookStepOne = Yup.object().shape({
  name: Yup.string().required("Este campo é obrigatório"),
  email: Yup.string().required("Este campo é obrigatório"),
  phone: Yup.string().required("Este campo é obrigatório"),
});

export const schemaEbookStepTwo = Yup.object().shape({
  enterprise: Yup.string().required("Este campo é obrigatório"),
  revenue: Yup.string().required("Este campo é obrigatório")
})
