import axios from 'axios'
import { IUser } from '../../interfaces/User';

function GetUserData() {
  const user = localStorage.getItem('user')
  if (user) return JSON.parse(user)
}

export const api = axios.create({
  baseURL: process.env.NODE_ENV !== 'production' ? 'http://localhost:3334' : 'https://apinode.tbcagro.com.br/',
  headers: { 'Content-type': 'application/json' }
});

api.interceptors.response.use(
  response => {
    return response
  },

  error => {
    return Promise.reject(error)
  },


)

api.interceptors.request.use(
  config => {
    const user: IUser = GetUserData()
    if (user && user.accessToken)
      config.headers.authorization = `Bearer ${user.accessToken}`
    return Promise.resolve(config)
  },

  (error: Error) => {
    return Promise.reject(error)
  }
)


export default api