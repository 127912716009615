import styled, { keyframes } from 'styled-components'

export const FormContainer = styled.section`
    width: 100%;
    height: 100%;
`


export const Title = styled.h1`
    font-size: 18px;
    color: #363636;
    font-weight: 600;
`

export const Subtitle = styled.h2`
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 20px;
    color: #404040;
    text-align: center;
    display: flex;
    font-weight: 400;
    justify-content: center;
    align-items: center;
`

export const Button = styled.button`
    margin-top: 20px;
    padding: 15px 30px;
    background: ${props => props.color? 'var(--orange)'  : 'var(--color-gradient)'};
    border: 2px solid ${props => props.color? 'var(--orange)' : 'var(--btn-primary)'};
    border-radius: 10px;
    cursor: pointer;

    font-size: 1rem;
    font-weight: 600;
    color: #363636;
`

export const BtnWpp = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #42CA4F;
  border: 1px solid #42CA4F;
  padding: 5px 15px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none
`

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const IconWpp = styled.img`
  width: 30px;
  margin-right: 10px;
`

export const Image = styled.img`
    width: 250px;
    margin: 20px 0;
`

export const FieldGroup = styled.section`
    display: flex;
    flex-direction: column;
    margin-top: 15px;
`

export const Label = styled.label`
    display: block;
    width: 100%;
    font-size: 1rem;
    color: #696969;
    margin-bottom: 5px;
`

export const Input = styled.input`
    display: block;
    width: 100%;
    line-height: 50px;
    height: 50px;
    padding: 0 10px;
    border: 1px solid #d6d6d6;
    transition: all .3s cubic-bezier(.175,.885,.32,1.275);
    border-radius: 4px;
    color: #333;
    font-size: 1rem;
` 

export const MaskedInputStyle = styled.input`
  input {
    display: block;
    width: 100%;
    line-height: 50px;
    height: 50px;
    padding: 0 10px;
    border: 1px solid #d6d6d6;
    transition: all .3s cubic-bezier(.175,.885,.32,1.275);
    border-radius: 4px;
    color: #333;
    font-size: 1rem;
  }
`

export const Select = styled.select`
    display: block;
    width: 100%;
    line-height: 50px;
    height: 50px;
    padding: 0 10px;
    border: 1px solid #d6d6d6;
    transition: all .3s cubic-bezier(.175,.885,.32,1.275);
    border-radius: 4px;
    color: #333;
    font-size: 1rem;
` 

export const Error = styled.p`
    font-size: 15px;
    color: red;
    margin-top: 5px;
`

export const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const LoadingContainer = styled.div`
  position: absolute;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-box-shadow: 0px 5px 5px 5px rgba(50, 50, 50, 0.3);
  -moz-box-shadow: 0px 5px 5px 5px rgba(50, 50, 50, 0.3);
  box-shadow: 0px 5px 5px 5px rgba(50, 50, 50, 0.3);
`

export const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;