import styled from 'styled-components';

import { FiChevronsDown } from 'react-icons/fi';

export const ArrowDown = () => {
    return (
        <ArrowContainer>
            <FiChevronsDown size={100} color="#fff" />
        </ArrowContainer>
    );
};

const ArrowContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    bottom: 64px;
    z-index: 1;
    @media (max-width: 1255px) {
        display: none;
    }
`;
