import React from 'react';
import { Overlay } from '../../../../styles';
import { Title } from '../../localStyles';
import { FlexContainer, ModalButton, ModalContainer } from './styles';
import { toast } from 'react-toastify';
import { baseApi as api } from '../../../../services/baseApi';

interface Props {
    isVisible: boolean,
    setIsVisible: (arg: boolean) => void,
    jobId: string,
    onError: () => void,
}

export function ConfirmDeleteModal({ isVisible, setIsVisible, jobId, onError }: Props) {

    async function deleteJob(){
        const token = localStorage.getItem('jobs_token')
        try {
            await api.delete(`/jobs/delete/${jobId}`, {
                headers: {
                    'authorization': `Bearer ${token}`
                }
            })
            toast.success('Vaga deletada com sucesso');
            setIsVisible(false)
        } catch (error:any) {
            if(error.response && error.response.data) {
                toast.error(error.response.data.error);

                if(error.response.status === 401){
                    onError()
                }
            };

            setIsVisible(false)
        }
    }

    return (
        <Overlay hidden={!isVisible}>
            <ModalContainer>
                <Title>Deseja realmente deletar essa vaga?</Title>
                <FlexContainer>
                    <ModalButton color="var(--red)" onClick={ deleteJob } >Sim, deletar</ModalButton>
                    <ModalButton color="var(--grey)" onClick={()=> setIsVisible(false) } >Não, cancelar</ModalButton>
                </FlexContainer>
            </ModalContainer>
        </Overlay>
    )
}