import React from 'react'

import { Formik, Form, Field } from "formik";
import { schemaFirstStep } from '../../../schemas';
import MaskedInput from 'react-text-mask'

import { Button, Error, FieldGroup, Input, Label } from '../styles';
import { ContainerBtn } from '../../../styles';

interface IFirstStep {
    name: string;
    email: string;
    phone: string;
}

interface Props {
    registerLead: ( data: IFirstStep, step: number) => Promise<void>;
}

const phoneNumberMask = [
    "(",
    /[1-9]/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];

const FirstStepForm: React.FC<Props> = ({ registerLead }) => {
    const initialValues: IFirstStep = { name: '', email: '', phone: '' }

    return (
        <Formik
            validationSchema={schemaFirstStep}
            initialValues={initialValues}
            onSubmit={(values, actions) => {
                (window as any).lintrk('track', { conversion_id: 6408697 });
                registerLead(values, 1)
                actions.setSubmitting(false);
            }}
       >
           {({ errors, touched  }) => (
            <Form>
                <br />
                <FieldGroup>
                    <Label htmlFor="name">Nome</Label>
                    <Field as={Input} name="name" placeholder="Seu nome" />
                    {errors.name && touched.name && ( <Error> {errors.name} </Error> )}
                </FieldGroup>
                <FieldGroup>
                    <Label htmlFor="email">Email</Label>
                    <Field as={Input} name="email" placeholder="Insira seu melhor email" />
                    {errors?.email && touched.email && ( <Error> {errors?.email} </Error> )}
                </FieldGroup>
                <FieldGroup>
                    <Label htmlFor="phone">Celular</Label>
                    <Field name="phone" type="number" placeholder="Insira seu número de telefone" render={({field}:any)=>(
                        <Input {...field} as={MaskedInput} mask={phoneNumberMask}   />
                    )} />
                    {errors?.phone && touched.phone && ( <Error> {errors?.phone} </Error> )}
                </FieldGroup>
                <ContainerBtn>
                    <Button color='white' type="submit">Próxima etapa</Button>
                </ContainerBtn>
            </Form>
           )}
       </Formik>
    )
}

export default FirstStepForm
