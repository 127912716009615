import styled from 'styled-components';
import { Section } from '../../../../styles';

export const SectionMedia = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    padding-bottom: 8rem;
    background-color: var(--grey);
    h1 {
        font-size: 2.4rem;
    }
    p {
        font-size: 1.2rem;
        color: #fff;
    }
    @media (max-width: 1255px) {
    }

    ${Section} {
        height: 100%;
        justify-content: space-around;
    }
`;
