import React, { useState } from 'react'
import { useHistory} from 'react-router'
import { Link } from 'react-router-dom'
import {toast, ToastContainer} from 'react-toastify'

import { api } from '../../../services/Admin/api'

import {AdminContainer, PageSubTitle, PageTitle, EnterButton, Error, Span} from '../globalStyles'
import { LogoImg, Form, Input} from './styles'

import logo from '../../../images/logo-dark.png'
import { Field, Formik } from 'formik'
import MaskedInput from 'react-text-mask'
import { UserLoginSchema } from '../../../schemas/userSchemas'
import { useAuth } from '../../../hooks/useAuth'
import { RequestNewPassword } from '../../../components/Admin/modals/RequestPasswordModal'


export default function Login(){

    const [isVisible, setIsVisible] = useState(false)

    const mask =  [
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/
      ];
    const history = useHistory()

    const {storageUser} = useAuth()

    async function signIn(credentials:{cpf: string, password: string}){
        try {
            const response = await api.post('/users/login', credentials)
            storageUser(response.data.user)
            history.push('/admin/inventario')

        } catch (error: any) {
            if(error.response.data){
                toast.warning(error.response.data.error)
            }
        }
    }

    return (
        <AdminContainer>
            <ToastContainer />
            <LogoImg src={logo} alt="TOTVS Unidade Brasil Central Agro" />
            <PageTitle>Sessão Administrativa</PageTitle>
            <PageSubTitle>Faça Login para prosseguir</PageSubTitle>
            <Formik 
            initialValues={{cpf:'', password: ''}}
            validationSchema={UserLoginSchema}
            onSubmit={values => signIn(values)}
            >
                {({
                    handleSubmit,
                    errors,
                    touched
                })=> (
                    <Form>
                        <Field  name="cpf" placeholder="Seu CPF"  render={({field}:any)=>(
                        <Input {...field} placeholder="Seu CPF" as={MaskedInput} mask={mask}   />
                    )}  />
                        {(errors.cpf && touched.cpf) && (<Error>{errors.cpf}</Error>)}

                        <Field type="password" as={Input} name="password" placeholder="Senha" />
                        {(errors.password && touched.password) && (<Error>{errors.password}</Error>)}
                        <EnterButton type="button" onClick={handleSubmit as ()=>void} >Entrar</EnterButton>
                    </Form>
                )}
            </Formik>
            <Span>Esqueceu a senha? 
                <Span onClick={()=> setIsVisible(true)} color="var(--blue)" >Clique aqui!</Span>
            </Span>
            <RequestNewPassword isVisible={isVisible} setIsVisible={setIsVisible} />
        </AdminContainer>
    )
}