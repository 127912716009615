
import LinkedinIcon from '../../../../images/social-medias/linkedin-blue.svg';
import { CardText, AuthorImage, CardContainer, CardTitle, Social } from './styles';

type CardProps = {
    photo: string;
    resume: string;
    name: string;
    linkedin: string;
};

export const Card: React.FC<CardProps> = (props: CardProps) => {
    return (
        <CardContainer>
            <AuthorImage>
                <img src={props.photo} alt="Ícone" />
            </AuthorImage>
            <CardTitle>{props.name}</CardTitle>
            <CardText>{props.resume}</CardText>
            <Social>
                <a href={props.linkedin}>
                    <img src={LinkedinIcon} alt="linkedin" />
                </a>
            </Social>
        </CardContainer>
    );
};
