import { Formik } from 'formik'
import React, { useEffect } from 'react'
import { FiX } from 'react-icons/fi'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../hooks/useAuth'
import { Error, Input, InputGroup, Label, PageSubTitle } from '../../../../pages/Admin/globalStyles'
import api from '../../../../services/Admin/api'
import { ModalContainer, Overlay } from '../global'
import { HighLightedText, SubmitButton, Text } from './styles'
import * as Yup from 'yup'
import { useHistory } from 'react-router'

interface Props {
  isVisible: boolean,
  setIsVisible: (arg: boolean) => void,
}

export function UserInfoModal({ isVisible, setIsVisible }: Props) {

  const { loadUserFromStorage, user, clearUserFromStorage } = useAuth()
  const history = useHistory()

  const schema = Yup.object().shape({
    password: Yup.string().required("O campo Senha é obrigatório"),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), '', 'As senhas não combinam']).required('O campo confirmar senha é obrigatório')
  })

  useEffect(() => {
    loadUserFromStorage()
  }, [])

  async function handleUpdatePassword(password: string) {
    try {
      await api.put(`/users/update/${user?._id}`, { password })
      toast.success('Senha atualizada com sucesso. Faça Login com sua nova senha')
      setIsVisible(false)
      clearUserFromStorage()
      setTimeout(()=>history.push('/admin/login'), 3000)
    } catch (error: any) {
      if (error.response.data) {
        toast.warning(error.response.data.error)
      }
    }
  }

  return (
    <Overlay hidden={!isVisible} >
      <ModalContainer >
        <FiX onClick={() => setIsVisible(false)} color="var(--red)" size={24} />
        <PageSubTitle>Suas informações</PageSubTitle>
        <Text>
          <strong>Nome:</strong> {user?.name}
        </Text>
        <Text>
          <strong>E-mail:</strong> {user?.email}
        </Text>
        <Text>
          <strong>CPF:</strong> {user?.cpf}
        </Text>

        <HighLightedText>
          Atualização de senha
        </HighLightedText>


        <Formik initialValues={{password: '', confirmPassword: ''}} 
        onSubmit={values => handleUpdatePassword(values.password)}
        validationSchema={schema}
        >
          {({ errors, touched,  handleSubmit, handleChange }) => (
            <>
              <InputGroup>
                <Label>Nova senha</Label>
                <Input onChange={handleChange} name="password" type="password" />
                {(errors.password && touched.password) && (<Error>{errors.password}</Error>)}
              </InputGroup>

              <InputGroup>
                <Label>Confirmar nova senha</Label>
                <Input onChange={handleChange} name="confirmPassword" type="password" />
                {(errors.confirmPassword && touched.confirmPassword) && (<Error>As senhas não combinam</Error>)}
              </InputGroup>

              <SubmitButton type="submit" onClick={handleSubmit as ()=>void}>Enviar</SubmitButton>
            </>
          )}
        </Formik>


      </ModalContainer>
    </Overlay>
  )
}