import { Field, Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import NumberFormat from 'react-number-format';
import {LeadDataStepOne } from '../../interfaces/EbookLeadData';
import { handleLaharIntegration } from '../../services/ebookApi';
import {schemaEbookStepOne} from '../../schemas/ebook'

interface StepOneProps {
    setFeedBackStep: (arg: number) => void;
    ebook: string;
}


export function StepOne(props: StepOneProps){
    return(
        <Formik
            validationSchema={schemaEbookStepOne}
            initialValues={{
            name: "",
            email: "",
            phone: "",
            }}
            onSubmit={async (
            values: LeadDataStepOne,
            { setSubmitting }: FormikHelpers<LeadDataStepOne>
            ) => {
            (window as any).lintrk('track', { conversion_id: 6408705 });
            (window as any).lintrkp('track', { conversion_id: 6342105 });
            await handleLaharIntegration(props.ebook,values)
            
            setSubmitting(false)
            props.setFeedBackStep(2)
            }}
        >
            {({ errors }) => (
            <Form className="form">
                    <div className="field-group">
                    <label htmlFor="name">Nome</label>
                    <Field id="name" name="name" placeholder="Seu nome" />
                    {errors.name && ( <p className="error"> {errors.name} </p> )}
                    </div>

                    <div className="field-group">
                    <label htmlFor="email">Email</label>
                    <Field
                        id="email"
                        name="email"
                        placeholder="Seu melhor email"
                        type="email"
                    />
                    </div>

                    <div className="field-group">
                    <label htmlFor="phone">Celular</label>
                    <Field
                        as={NumberFormat}
                        format="(##) # ####-####" mask="_"
                        id="phone"
                        name="phone"
                        placeholder="Seu número de phone"
                    />
                    </div>

                    <button  className="btn-form" type="submit">Quero o ebook</button>
            </Form>
            )}
        </Formik>
    )
}