import logoPecege from '../../../../images/projetos-pecege-branco.png';
import logoTotvs from '../../../../images/logo.png';
import facebookIcon from '../../../../images/social-medias/facebook.svg';
import youtubeIcon from '../../../../images/social-medias/youtube.svg';
import linkedinIcon from '../../../../images/social-medias/linkedin.svg';
import instagramIcon from '../../../../images/social-medias/instagram.svg';

import SocialMedias from './SocialMedias';

import { ImageLogo, Paragraph } from '../../../../styles';
import {
    FooterContainer,
    IconsMediasContainer,
    Pecege,
    SectionMediasContainer,
    Totvs,
    Wrapper,
} from './styles';

type FooterProps = {
    backgroundColor?: string;
};

function Footer({ backgroundColor = 'var(--blue)' }: FooterProps) {
    return (
        <FooterContainer color={backgroundColor}>
            <Wrapper>
                <Totvs>
                    Realização
                    <ImageLogo src={logoTotvs} />
                </Totvs>
                <Pecege>
                    parceria
                    <ImageLogo src={logoPecege} />
                </Pecege>
            </Wrapper>
            <SectionMediasContainer>
                <Paragraph color="true">
                    Nos acompanhe nas redes sociais
                </Paragraph>
                <IconsMediasContainer>
                    <SocialMedias
                        img={linkedinIcon}
                        uri="linkedin.com/company/totvsbrasilcentralagro/"
                    />
                    <SocialMedias
                        img={instagramIcon}
                        uri="instagram.com/totvsbrasilcentral_unidadeagro/"
                    />
                </IconsMediasContainer>
            </SectionMediasContainer>
        </FooterContainer>
    );
}

export default Footer;
