import React from 'react'

import { Btn } from "./styles";

interface IProps {
    content: any;
    cta?: () => void;
    background?: string;
    color?: string;
    onClick?: () => void
    bgColor?: string
}

const Button: React.FC<IProps> = ({content, cta, color, onClick, bgColor}) => {
    
    /*check if an optional method exists and execute it*/
    function execute(){
        cta && cta()
        onClick && onClick()
    }

    return (
        <Btn bgColor={bgColor} color={color} onClick={execute}>
            {content}
        </Btn>
    )
}

export default Button
