import styled from "styled-components";

export const AdminContainer = styled.div`
  background: var(--lightgrey);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;
  min-height: 100vh;
  padding: 36px;
`;

export const PageTitle = styled.h1`
  font-family: Ubuntu, sans-serif;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  color: var(--grey);
  text-align: center;
`;

export const PageSubTitle = styled.h2`
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: var(--grey);
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
`;

export const Error = styled.span`
  color: var(--red);
  font-family: Nunito, sans-serif;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: -25px;
  padding: 5px;
`;

export const EnterButton = styled.button`
  background: var(--blue-darker);
  width: 450px;
  max-width: 100%;
  height: 72px;
  outline: none;
  border: 0;
  border-radius: 5px;
  margin-top: 45px;

  font-family: Nunito, sans-serif;
  font-weight: bold;
  font-size: 21.9153px;
  line-height: 30px;
  color: var(--white);
  transition: 0.2s;

  &:hover {
    filter: brightness(1.2);
    cursor: pointer;
  }
`;

export const Link = styled.a`
  font-family: Nunito, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: justify;
  margin-top: 20px;
  color: ${(props) => (props.color ? props.color : "var(--grey-lighter)")};
  text-decoration: none;
`;

export const ArrowRightIcon = styled.div`
  height: 100%100vh;
  width: 85px;
  background: var(--blue);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

export const Span = styled.span`
  font-family: Nunito, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: justify;
  margin-top: 20px;
  color: ${(props) => (props.color ? props.color : "var(--grey-lighter)")};
`;

export const Input = styled.input`
  height: 56px;
  border-radius: 5px;
  border: 0;
  outline: none;
  background: var(--input-background);
  max-width: 100%;
  width: ${(props) => (props.width ? props.width : "300px")};
  padding: 10px;
`;

export const TextArea = styled.textarea`
  min-height: 100px;
  border-radius: 5px;
  border: 0;
  outline: none;
  background: var(--input-background);
  max-width: 100%;
  padding: 10px;
  resize: none;

  ::-webkit-scrollbar-thumb {
    visibility: hidden;
  }
`;

export const Label = styled.label`
  font-family: Nunito;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: var(--grey);
`;

export const FlexRowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const FlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 45px;
  width: 100%;
  justify-content: space-between;
`;

export const IconCircle = styled.div`
  padding: 5px;
  border-radius: 50%;
  background: ${(props) => props.color};
  min-height: 45px;
  min-width: 45px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;

  &:hover {
    filter: brightness(1.3);
    cursor: ${(props) =>
      props.color == "var(--grey-lighter)" ? "not-allowed" : "pointer"};
  }

  @media (max-width: 999px) {
    min-width: 25px;
    min-height: 25px;

    svg {
      max-width: 20px;
      max-height: 20px;
    }
  }
`;

export const NotFoundContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;

  h2 {
    align-self: center !important;
  }
`;
export const NotFoundImg = styled.img`
  width: 200px;
  max-width: 100%;
  margin-top: 5%;
`;

export const Loader = styled.div`
  border: 16px solid var(--white);
  border-radius: 50%;
  border-top: 16px solid var(--blue);
  border-bottom: 16px solid var(--blue);
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  align-self: center;
  justify-self:center;
  margin-top: 50px;

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LogoImg = styled.img`
  max-height: 80px;
  max-width: 100%;
  align-self: flex-start;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;
export const Menu = styled.div`
  display: flex;
  flex-direction: row;

  svg {
    transition: 0.2s;
  }

  div {
    margin: 10px;
  }

  svg:hover {
    cursor: pointer;
    filter: brightness(1.5);
  }

  @media (max-width: 700px) {
    position: absolute;
    right: 0;
    top: 5px;

    svg {
      width: 25x;
      height: 25px;
    }
  }

  @media (max-width: 400px) {
    background: var(--lightgrey);
    position: fixed;
    bottom: 0;
    top: auto;
    width: 100%;
    align-items: center;
    justify-content: space-around;
  }
`;
export const IconGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const IconText = styled.span`
  font-family: Nunito;
  font-weight: 700;
  font-size: 13.7559px;
  line-height: 19px;
  color: ${(props) => props.color};
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: transparent;

  h2 {
    align-self: flex-start;
  }
`;

export const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: var(--blue);
  transition: 0.2s;

  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }
`;

export const Text = styled.span`
  font-family: Nunito;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: var(--grey);
  margin-right: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  max-height: 100%;
`;
export const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
`;

export const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 15% 30% 25% 15% 15%;
  gap: 10px;
  background: var(--grey);
  align-items: center;
  justify-items: start;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 55px;
  padding: 10px;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: row;

    span:last-child {
      margin-left: 40%;
    }
  }
`;
export const TableHeaderText = styled.span`
  font-family: Nunito;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: var(--white);
`;

export const TableRow = styled.div`
  display: grid;
  grid-template-columns: 15% 30% 25% 15% 15%;
  padding: 10px;
  background: var(--white);
  align-items: center;
  justify-items: start;
  gap: 10px;
  height: 105px;
  border-bottom: 1px solid var(--grey);

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 170px;
    width: 100%;

    span {
      max-width: 90%;
    }
  }
`;

export const KeyWordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
