import styled from 'styled-components';

type btnProps = {
    color?: string;
    bgColor?: string;
}

export const Btn = styled.button<btnProps>`
    padding: 15px 30px;
    background: ${(props) => (props.bgColor ? props.bgColor : 'var(--orange)')};
    cursor: pointer;
    color: ${(props) => (props.color ? props.color : 'var(--grey)')};
    font-weight: 600;
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid
        ${(props) => (props.bgColor ? props.bgColor : 'var(--orange)')};
    transition: 0.5s;

    &:hover {
        transform: translateY(-5px);
    }
`;
