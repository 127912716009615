import renatoPhoto from './assets/profile-photos/renato-barbosa-1.png';
import haroldoPhoto from './assets/profile-photos/haroldo-torres-1.png';
import rogerioPhoto from './assets/profile-photos/rogerio-luiz-varanda-1.png';
import marianaBraga from './assets/profile-photos/mariana-braga.png';

export const data = {
    title: 'Saiba Sobre o Impacto da Inovação nos Custos Agroindustriais do Setor Sucroenergético',
    subTitle: '',
    description:
        'Conheça as soluções para o agronegócio em termos de informação e apuração do custo de produção e descubra como ter comparabilidade entre os indicadores por bases diferentes de rateio e apropriação de custo no setor sucroenergético.',
    date: '24/02 das 9h às 12h',
    CTA: 'INSCREVA-SE GRATUITAMENTE',
    youtubeEmbed: 'og7Z9zP6cj8',
    titleBenefits: 'O que você vai aprender no evento: ',
    titlePresenters: 'Grandes especialistas do agronegócio reunidos:',
    presenters: [
        {
            name: 'RENATO BARBOSA CAETANO',
            resume: 'Graduado em Ciência da Computação com ênfase em Análise de Sistemas, Pós Graduação em Análise de Sistemas com especialização em Banco de Dados e MBA em Agronegócio. Mais de 20 anos de experiência no desenvolvimento de soluções para o Agronegócio no Brasil e América Latina. Atua como Gerente de Produto no segmento de Agronegócio da TOTVS.',
            linkedin:
                'https://www.linkedin.com/in/renato-barbosa-caetano-48190629/',
            photo: renatoPhoto,
        },
        {
            name: 'HAROLDO TORRES',
            resume: 'Economista (2013) e mestrado (2016) em Economia Aplicada pela Universidade de São Paulo e doutorado em Economia Aplicada pela Escola Superior de Agricultura Luiz de Queiroz da Universidade de São Paulo (2019). Atualmente é gestor de projetos e docente da Faculdade PECEGE. Também é criador e idealizador do Canal EconomicaMente. Possui experiência na área de Economia Agrícola e pesquisas nos setores citrícola e sucroenergético.',
            linkedin: 'https://www.linkedin.com/in/haroldojtorres/',
            photo: haroldoPhoto,
        },
        {
            name: 'ROGÉRIO VARANDA',
            resume: 'Bacharel em Ciências Contábeis e MBA em Gestão de Agronegócio é especialista de implantação do sistema PIMS - Módulos de Planejamento, Orçamento, Custo e Exaustão. Possui mais de 35 anos de experiência nas áreas de Auditoria, Controladoria, Planejamento, Custo e Orçamento. E já participou de mais de 50 projetos no segmento de bioenergia e grãos. Hoje Especialista Processo de Orçamento e Custo TOTVS Bioenergia e Multicultivo.',
            linkedin:
                'https://www.linkedin.com/in/rogerio-luiz-varanda-16437049/',
            photo: rogerioPhoto,
        },
        {
            name: 'MARIANA BRAGA',
            resume: 'Bacharel em Administração de Empresas  com ênfase em Finanças e MBA em Finanças e Banking. Mais de 14 anos de experiência na área financeira atuando no segmento de bancos e cooperativas de crédito. Hoje Atua como Executiva de Negócios na área de Techfin na TOTVS Brasil Central.',
            linkedin:
                'https://www.linkedin.com/in/mariana-braga-de-paula-53521b29/',
            photo: marianaBraga,
        },
    ],
    CTADemo: 'QUERO PARTICIPAR!',
    laharIdentifier: 'EVENTO TOTVS AGRO DAY',
    draft: false,
};
