import React, { useState } from 'react'
import { SearchContainer, SearchInput } from './styles'
import {FiSearch} from 'react-icons/fi'
import api from '../../../services/Admin/api'
import { toast, ToastContainer } from 'react-toastify'
import { IRoutine } from '../../../interfaces/routine'


interface SearchBarProps{
    setData: (data: IRoutine[]) => void,
    type:string,
}


export function SearchBar({setData, type}: SearchBarProps){
    const [query, setQuery] = useState('')

    async function Search(){
        try {
          const response = await api.get(`/${type}/search?query=${query}`)
          
          setData(response.data)
        } catch (error:any) {
          
          if(error.response.data){
              toast.error(error.response.data.error)
          }
        }
      }

    return (
        <SearchContainer>
            <ToastContainer/>
            <SearchInput onChange={ e=> setQuery(e.target.value)} name="search" placeholder="Pesquisar" />
            <FiSearch onClick={Search} color="var(--grey-lighter)" size={15} />
        </SearchContainer>
    )
}