import {useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';

import LogoImg from "../../../images/logo.png";
import EbookImg from "../../../images/ebook/ebook-soja.png";

import "./styles.css";
import { StepOne } from "../../../components/EbookFormComponent/StepOne";
import { StepTwo } from "../../../components/EbookFormComponent/StepTwo";

export function EbookSoja() {

  const [feedBackStep, setFeedBackStep] = useState(1)

  const notify = () => toast.success("O e-book foi enviado para o seu email!");

  useEffect(() => {
    document.title = "Ebook da soja gratuito"
  }, [])

  return (
    <main className="page-container">
      <ToastContainer />
      <div className="container">
        <img
          className="image-logo"
          src={LogoImg}
          alt="Totvs | Unidade Brasil Central Agro"
        />
        <div className="headline">
          <div className="headline-container">
            <h1>Ebook da Soja | </h1> 
            <h1><span>De <span id="span-price">197</span> por</span> R$ 0,00</h1>
          </div>
          <h2>Os números impressionantes da recordista do Brasil: Soja</h2>
          <h3>Saia na frente mesmo em meio à crise e inflação</h3>
        </div>
        <section className="ebook-form-container">
          <div className="ebook-container">
            <div className="visual-element"></div>
            <img
              className="ebook-image"
              src={EbookImg}
              alt="Arte Ebook Soja"
            />
            <div className="visual-element-ebook"></div>
          </div>
          <div className="form-container">
            <h3>Preencha seus dados para receber o e-book</h3>
            <div className="visual-element-form-top"></div>

            {feedBackStep !==2? <StepOne ebook="EBOOK SOJA" setFeedBackStep={setFeedBackStep} /> : <StepTwo ebook="EBOOK SOJA" notify={notify} />}

            <div className="visual-element-form"></div>
          </div>
        </section>
      </div>
    </main>
  );
}
