import styled from 'styled-components'

export const LinkToMedia = styled.a`
    margin: 0 5px;
`

export const IconSocialMedia = styled.img`
    width: 40px;
    height: 40px;
`

export const SectionMediasContainer = styled.section`
    display: flex;
    flex-direction: column;
`
export const TitleSocialMedias = styled.h4`
    color: #fff;
`