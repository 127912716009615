import { LeadDataStepTwo, LeadDataStepOne, LeadData } from './../interfaces/EbookLeadData';
import axios from "axios";
import { laharEndPoint, laharToken } from "../environment/environment.json";

export async function handleLaharIntegration
(ebook:string,dataStepOne?: LeadDataStepOne| null, dataStepTwo?: LeadDataStepTwo| null, data?: LeadData) : Promise<void> {

    if(data){
        localStorage.setItem('contact-email', data.email)

        const params=  {
            jsoncallback: " ",
            token_api_lahar: laharToken,
            email_contato: data.email,
            nome_contato:data.name,
            tel_celular: data.phone,
            estado: data.state, 
            nome_conversao: ebook,
            nome_formulario: ebook,
            tipo_integracao: "conversions"
        }

        const response = await axios.get(laharEndPoint, {params})
    }

    if(dataStepOne) {

        localStorage.setItem('contact-email', dataStepOne.email)

        const params=  {
            jsoncallback: " ",
            token_api_lahar: laharToken,
            email_contato: dataStepOne.email,
            nome_contato:dataStepOne.name,
            tel_celular: dataStepOne.phone,
            //estado: state, 
            nome_conversao: `${ebook} | ETAPA 01`,
            nome_formulario: `${ebook} | ETAPA 01`,
            tipo_integracao: "conversions"
        }

        const response = await axios.get(laharEndPoint, {params})
        
    }

    if(dataStepTwo){
        const params=  {
            jsoncallback: " ",
            token_api_lahar: laharToken,
            email_contato : localStorage.getItem("contact-email"),
            nome_empresa: dataStepTwo.enterprise,
            GbMh954KPa: dataStepTwo.revenue,
            nome_conversao: `${ebook} | ETAPA 02`,
            nome_formulario: `${ebook} | ETAPA 02`,
            tipo_integracao: "conversions"
        }

        const response = await axios.get(laharEndPoint, {params})
        
    }

}
