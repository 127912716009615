import styled from "styled-components";

export const ModalContainer = styled.div`
  width: 550px;
  max-width: 100%;
  max-height: 90vh;
  background: var(--white);
  padding: 2.5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
  transition: 0.2s;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: justify;
`;

export const FlexContainer =styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
`

export const ModalButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  max-width: 100%;
  height: 40px;
  border-radius: 5px;
  border:0;
  outline: none;
  color: var(--white);
  background: ${props => props.color};

  transition: 0.2s;

  &:hover{
    cursor: pointer;
    filter: brightness(1.2);
  }

`
