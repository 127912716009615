import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { FiArrowLeft } from 'react-icons/fi'
import 'rc-slider/assets/index.css'
import { Formik, Field } from 'formik'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { AdminContainer, Input, PageSubTitle, PageTitle, Label, EnterButton, Error, InputGroup } from '../globalStyles'
import { LogoImg, Header, IconContainer, ReturnText, Form, GridContainer, FlexContainer } from './styles'
import logo from '../../../images/logo-dark.png'
import Slider from '../../../components/Admin/Slider'
import { UserUpdateSchema } from '../../../schemas/userSchemas'
import { api } from '../../../services/Admin/api'
import { IUser } from '../../../interfaces/User'
import MaskedInput from 'react-text-mask'
import { useAuth } from '../../../hooks/useAuth'

interface Params{
  id: string
}

export function UserUpdate() {
  const [isActive, setIsActive] = useState(false)
  const [currentUser, setCurrentUser] = useState<IUser>()

  const history = useHistory()
  const { loadUserFromStorage,clearUserFromStorage, user } = useAuth()

  const {id} = useParams() as Params

  const mask = [
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/
  ];

  function handleNavigateToUsersManager() {
    history.push('/admin/usuarios')
  }

  async function handleUpdateUser(data: IUser) {
    const dataWithoutPassword = {
      name: data.name,
      email: data.email,
      cpf: data.cpf,
      isAdmin: data.isAdmin
    }

    try {
      await api.put(`/users/update/${id}`, (data.password && data.password !== '')? data : dataWithoutPassword )
      toast.success('Usuário atualizado com sucesso!')
      if(currentUser?._id === user?._id && data.password !== ''){
        clearUserFromStorage()
        setTimeout(()=>history.push('/admin/login'), 4000)
      } else{
        setTimeout(()=>history.push('/admin/usuarios'), 4000)
      }
      
    } catch (error: any) {
      if (error.response.data) {
        toast.warning(error.response.data.error)
        if(error.response.status === 401) setTimeout( () => history.push('/admin/login'), 3000)
      }
    }
  }

  useEffect(()=>{
    async function handleLoadCurrentUser(){
      try {
        const response = await api.get(`/users/details/${id}`)
        setCurrentUser(response.data)
      } catch (error:any) {
        if(error.response.data){
          toast.warning(error.response.data.error)
          if(error.response.status === 401) setTimeout( () => history.push('/admin/login'), 3000)
        }
      }
    }
    handleLoadCurrentUser()
    loadUserFromStorage()
  }, [])

  useEffect(()=>{
    currentUser &&  setIsActive(currentUser?.isAdmin? true : false)
  },[currentUser])

  return (
    <AdminContainer>
      <ToastContainer />
      <Header>
        <LogoImg src={logo} />
        <IconContainer>
          <FiArrowLeft onClick={handleNavigateToUsersManager} color="var(--blue)" size={24} />
          <ReturnText>Voltar para o inventário</ReturnText>
        </IconContainer>
      </Header>
      {currentUser && (
        <Formik
        initialValues={{ name: currentUser.name, email: currentUser.email, cpf: currentUser.cpf, password: '', confirmPassword:'', isAdmin: currentUser.isAdmin }}
        onSubmit={values => handleUpdateUser({ ...values, isAdmin: isActive })}
        validationSchema={UserUpdateSchema}
      >
        {({ errors, touched, handleSubmit }) => (
          <Form>
            <PageTitle>Atualização de usuário</PageTitle>
            <FlexContainer>
              <PageSubTitle>Dados</PageSubTitle>
              { ( !currentUser?.isMaster && currentUser?._id !== user?._id)  ? <Slider setSlider={setIsActive} isActive={isActive} /> : ''}
            </FlexContainer>

            <InputGroup>
              <Label htmlFor="name" >Nome:</Label>
              < Field as={Input} name="name" width="100%" />
              {(errors.name && touched.name) && (<Error>{errors.name}</Error>)}
            </InputGroup>

            <GridContainer>

              <InputGroup>
                <Label htmlFor="email" >E-mail:</Label>
                <Field as={Input} name="email" />
                {(errors.email && touched.email) && (<Error>{errors.email}</Error>)}
              </InputGroup>

              <InputGroup>
                <Label htmlFor="cpf" >CPF:</Label>
                <Field name="cpf" placeholder="Seu CPF" render={({ field }: any) => (
                  <Input {...field} placeholder="Seu CPF" as={MaskedInput} mask={mask} />
                )} />
                {(errors.cpf && touched.cpf) && (<Error>{errors.cpf}</Error>)}
              </InputGroup>

              <InputGroup>
                <Label htmlFor="password" >Senha:</Label>
                <Field as={Input} type="password" name="password" />
                {(errors.password && touched.password) && (<Error>{errors.password}</Error>)}
              </InputGroup>

              <InputGroup>
                <Label htmlFor="confirmPassword" >Confirmar senha:</Label>
                <Field as={Input} type="password" name="confirmPassword" />
                {(errors.confirmPassword && touched.confirmPassword) && (<Error>As senhas não combinam</Error>)}
              </InputGroup>

            </GridContainer>

            <EnterButton type="submit" onClick={handleSubmit as () => void} >Atualizar</EnterButton>

          </Form>
        )}
      </Formik>
      )}
    </AdminContainer>
  )
}