import React, { ReactNode } from 'react';

import { Paragraph } from '../../styles';

import { ContainerDetails, ImageIcon, Link } from './styles';

interface EventsDatailsProps {
    image?: string;
    text?: string;
    icon?: ReactNode;
    url?: string;
}

const EventDetails = ({ image, text, icon, url }: EventsDatailsProps) => {
    return (
        <ContainerDetails>
            {image ? <ImageIcon src={image} /> : icon}
            {url ? (
                <Link href={url} target="_blank">
                    <Paragraph color="true">{text}</Paragraph>
                </Link>
            ) : (
                <Paragraph color="true">{text}</Paragraph>
            )}
        </ContainerDetails>
    );
};

export default EventDetails;
