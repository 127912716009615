import styled from "styled-components";

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  height: 100vh;
  background: var(--lightgrey);
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  max-width: 500px;
  width: 100%;
`;

export const Text = styled.span`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
	color: var(--grey);
`;

export const Title = styled.h1`
	font-family: Ubuntu;
	font-style: normal;
	font-weight: 500;
	font-size: 28px;
	line-height: 30px;
	color: var(--grey);
	margin-top:10px;
	margin-bottom:20px;
`;

export const Link = styled.a`
	width: 600px;
	max-width: 90%;
	height: 55px;
	background: ${props => props.color? props.color : 'var(--blue)' };

	display: flex;
	align-items: center;
	padding: 20px;
	gap: 20px;
	margin-bottom: 20px;
	border-radius: 5px;

	text-decoration: none;
	color: var(--lightgrey);
	transition: 0.2s;

	&:hover{
		background: transparent;
		border: 2px solid ${props => props.color? props.color : 'var(--blue)' };
		color: ${props => props.color? props.color : 'var(--blue)' };

		svg{
			color: ${props => props.color? props.color : 'var(--blue)' } !important;
		}
	}
`
