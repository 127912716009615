import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from './pages/Home';
import { EbookMilho } from './pages/Ebook/Milho';
import AgriculturalCost from './pages/AgriculturalCost';
import { EbookSoja } from './pages/Ebook/Soja';
import Login from './pages/Admin/Login';
import { Register } from './pages/Admin/UserRegister';
import { Inventary } from './pages/Admin/Inventary';
import { RoutineRegister } from './pages/Admin/RoutineRegister';
import { RoutineUpdate } from './pages/Admin/RoutineUpdate';
import { UsersManager } from './pages/Admin/usersManager';
import { UserUpdate } from './pages/Admin/UserUpdate';
import ResetPassword from './pages/Admin/ResetPassword';
import { JobsList } from './pages/Jobs/List';
import { JobRegister } from './pages/Jobs/Register';
import TotvsAgroDay from './pages/TotvsAgroDay';

const Routes: React.FC = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Home} />
                <Route
                    path="/eventos/custo-agricola"
                    exact
                    component={AgriculturalCost}
                />
                <Route
                    path="/eventos/totvs-agro-day"
                    exact
                    component={TotvsAgroDay}
                />
                <Route path="/ebook/milho" exact component={EbookMilho} />
                <Route path="/ebook/soja" exact component={EbookSoja} />
                <Route path="/admin/login" component={Login} />
                <Route path="/admin/cadastro" component={Register} />
                <Route
                    path="/admin/usuario/atualizar/:id"
                    component={UserUpdate}
                />
                <Route path="/admin/rotina/criar" component={RoutineRegister} />
                <Route
                    path="/admin/rotina/atualizar/:id"
                    component={RoutineUpdate}
                />
                <Route path="/admin/inventario" component={Inventary} />
                <Route path="/admin/usuarios" component={UsersManager} />
                <Route
                    path="/admin/nova_senha/:token/:email"
                    component={ResetPassword}
                />

                <Route path="/vagas" exact component={JobsList} />
                <Route path="/vagas/admin" exact component={JobsList} />
                <Route path="/vagas/cadastro" component={JobRegister} />
                <Route path="/vagas/atualizar" component={JobRegister} />
                <Route component={Home} />
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;
