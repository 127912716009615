import * as Yup from "yup";

export const RoutineRegisterSchema = Yup.object().shape({
    name: Yup.string().required('O campo nome é  obrigatório'),
    description: Yup.string().required('A descrição é um campo obrigatório obrigatório'),
    notes: Yup.string(),
    keyWords: Yup.array<String>().required('Pelo menos uma palavra-chave é obrigatória')
})

export const RoutineUpdateSchema = Yup.object().shape({
    name: Yup.string(),
    description: Yup.string(),
    notes: Yup.string(),
    keyWords: Yup.array<String>()
})

