import styled from "styled-components";

export const SectionAuthorContainer = styled.section`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const AuthorContainer = styled.section`
  display: flex;
  width: 90%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`

export const AuthorImage = styled.img`
  width: 250px;
  height: 250px;
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
  margin: 20px;

  @media (max-width: 400px) {
    margin: 20px 0;
  }
`

export const AuthorTitle = styled.h1`
    color: #363636;
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 24px;
`

export const AuthorLinkedin = styled.a`
    display: flex;
    align-items: center;
    margin-left: 5px;
`
