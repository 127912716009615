import React from 'react'
import { Bar, Circle, SliderContainer, SliderLabel, SliderContent } from './styles'

interface SliderProps {
  setSlider: (arg: boolean) => void;
  isActive: boolean
}

export default function Slider(props: SliderProps) {

  return (
    <SliderContainer onClick={() => props.setSlider(!props.isActive)} >
      <SliderLabel>Tornar admin?</SliderLabel>
      <SliderContent>
        <Circle className={props.isActive ? 'active' : ''} />
        <Bar className={props.isActive ? 'active' : ''} />
      </SliderContent>
    </SliderContainer>
  )
}