import styled from 'styled-components'


export const Main = styled.main`
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    min-height: 100vh;
    align-items: center;
    padding: 36px 86px 0px 86px;
    background: var(--lightgrey);

    @media(max-width:700px){
        padding: 20px;
    }
`

export const Image = styled.img`
    width: 100%;
    max-width: 540px;
`

export const Title = styled.h1`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;
    color: var(--grey);
    align-self: center;
    text-align: center;
`

export const Text = styled.span`
  font-family: Nunito;
  font-style: normal;
  font-weight: 300;
  font-size: ${props => props.className ==='large-text'? '20px' : props.className==="medium-text"? '15px' : '10px'};
  line-height: ${props => props.className ==='large-text'? '30px' : props.className==="medium-text"? '25px' : '15px'};
  margin-top: 5px;
  margin-bottom: 5px;
  color: ${props => props.color? props.color : 'var(--grey-lighter)'};
  
`;

export const Button = styled.button`
  width: 120px;
  min-height: 24px;
  justify-self: flex-end;
  background: ${props => props.color || 'var(--blue)'};
  border: 0;
  outline: none;
  border-radius: 5px;

  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: var(--white);

  transition: 0.2s;

  &:hover{
    filter: brightness(1.2);
    cursor: pointer;
  }
`;

export const Input = styled.input`
    border: 0;
    outline: none;
    max-width: 80vw;
    width:450px;
    border-radius: 5px;
    height: 45px;
    padding: 5px;

    color: var(--grey-lighter);
    font-family: Ubuntu, sans-serif;
`

export const TextArea = styled.textarea`
    border: 0;
    outline: none;
    max-width: 80vw;
    width:450px;
    border-radius: 5px;
    height: 100px;
    padding: 5px;
    resize: none;
    color: var(--grey-lighter);
    font-family: Ubuntu, sans-serif;

    ::-webkit-scrollbar {
        width: 5px;
    }
`

export const Label = styled.label`
    color: var(--grey-lighter);
    font-family: Ubuntu, sans-serif;
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 10px;
`

export const InputGroup = styled.div`
    display: flex;
    flex-direction:column;
`

export const SubmitButton = styled.button`
    border: 0;
    outline: none;
    background:var(--blue);
    border-radius: 5px;
    width:450px;
    max-width: 80vw;
    height: 45px;
    margin-bottom: 40px;
    color: var(--white);
    font-size: 16px;
    transition: 0.2s;

    &:hover{
        cursor: pointer;
        filter: brightness(1.2);
    }
`

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 40px;
    gap: 15px;
`

export const Error = styled.span`
  font-size: 12px;
  font-family: Ubuntu, sans-serif;
  color: var(--red);
  margin-top: 10px;

`