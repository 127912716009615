import React, { useEffect, useState } from 'react';

import Button from '../../components/Button';
import CardBenefits from '../../components/Card';
import ElementDivider from '../../components/ElementDivider';
import { ModalView } from '../../components/ModalView';
import FormComponent from './components/form';
import EventDetails from '../../components/EventDetails';

import background from '../../images/totvs-agro-day/bg.png';
import logoTotvs from '../../images/logo.png';
import RocketIcon from '../../images/icons-totvs/rocket-icon.png';
import TargetIcon from '../../images/icons-totvs/alvo-icon.png';
import StarIcon from '../../images/icons-totvs/star-icon.png';
import downBarsImg from '../../images/downBars.png';
import upBarsImg from '../../images/upBars.png';
import { FiCalendar, FiClock, FiMapPin } from 'react-icons/fi';
import { data as eventData } from './totvs-agro-day-data';

import {
    ImageLogo,
    Header,   
    Section,
    Title,
    SubTitle,
    Paragraph,
    SectionContentText,
    ContainerBtn,
    ContainerCardsBenefits,
    UpBars,
    DownBars,
    Iframe,
    SectionContentMedia,
} from '../../styles';

import {  ContainerPresenters, HeadContent, Headline } from './styles';
import { Card } from './components/presenter-card';
import { SectionPresenters } from './components/section-presenters';
import { SectionMedia } from './components/section-media';
import { ArrowDown } from './components/arrow-down';
import { Logo } from './components/logo';
import { EventDetailsWrapper } from '../../components/EventDetails/styles';
import Footer from './components/footer';

const TotvsAgroDay: React.FC = () => {
    const pageContent: any = eventData;

    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        localStorage.setItem('page_identifier', pageContent.laharIdentifier);
        document.title = 'EVENTO TOTVS AGRO DAY';
    }, [pageContent.laharIdentifier]);

    function handleChangeOpenModal() {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <ModalView closeModal={handleChangeOpenModal} isOpen={isOpen}>
                <FormComponent />
            </ModalView>
            <Headline src={background}>
                {/* <UpBars src={upBarsImg} /> */}
                {/* Remove if no background image is provided to fix the color */}
                {/* <BackgroundOp /> */}
                <Header>
                    <ImageLogo src={logoTotvs} />
                </Header>
                <HeadContent>
                    <Section>
                        <SectionContentText>
                            <Logo />
                            <Title>{pageContent?.title}</Title>
                            <EventDetailsWrapper>
                                <EventDetails
                                    text="24/02"
                                    icon={
                                        <FiCalendar color="white" size={30} />
                                    }
                                />
                                <EventDetails
                                    text="9h às 12h"
                                    icon={<FiClock color="white" size={30} />}
                                />
                                <EventDetails
                                    text="Evento híbrido"
                                    icon={<FiMapPin color="white" size={30} />}
                                    url="https://www.google.com/maps/place/Brookfield+Towers/@-16.7027685,-49.2397029,13z/data=!4m5!3m4!1s0x0:0x7200c09501de15dd!8m2!3d-16.7048187!4d-49.2400818?hl=pt-BR"
                                />
                            </EventDetailsWrapper>
                            <ContainerBtn align>
                                <Button
                                    color="white"
                                    bgColor="var(--blue)"
                                    background="true"
                                    content={pageContent?.CTA}
                                    cta={handleChangeOpenModal}
                                />
                            </ContainerBtn>
                        </SectionContentText>
                    </Section>
                </HeadContent>
                <ArrowDown />
                {/* <DownBars src={downBarsImg} /> */}
            </Headline>
            <SectionMedia>
                <Section>
                    <SectionContentText>
                        <Title>Por que participar deste evento?</Title>
                        <Paragraph color="true">
                            {pageContent?.description}
                        </Paragraph>
                    </SectionContentText>
                    <SectionContentMedia>
                        <Iframe
                            width="560"
                            height="315"
                            src={`https://www.youtube.com/embed/${pageContent?.youtubeEmbed}`}
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        ></Iframe>
                    </SectionContentMedia>
                </Section>
            </SectionMedia>
            
            <ContainerCardsBenefits>
                <SubTitle>Benefícios para quem assistir o evento</SubTitle>
                <Section>
                    <CardBenefits
                        image={TargetIcon}
                        title="Como reduzir os custos sem comprometer o seu negócio?"
                        text="Em tempos de crise e incertezas ou queda no preço das commodities a pressão para reduzir custos é sempre preocupante. Aprenda como reduzir os custos sem comprometer o seu negócio."
                    />
                    <CardBenefits
                        image={StarIcon}
                        title="O que é relevante na formação dos custos na operação Agroindustrial?"
                        text="Descubra quais os recursos relevantes consumidos na sua operação e entenda a influência desses custos no processo agroindustrial."
                    />
                    <CardBenefits
                        image={RocketIcon}
                        title="Como melhorar as margens de ganho e rentabilidade?"
                        text="Entenda como o conhecimento da formação do custo da lavoura o ajudará a ter maior segurança no momento da negociação no mercado de commodities, o que resulta em uma usina com maior rentabilidade."
                    />
                    
                </Section>
            </ContainerCardsBenefits>
            <ElementDivider id="demo" />
            <SubTitle>{pageContent?.titlePresenters}</SubTitle>
            <SectionPresenters>
                <ContainerPresenters>
                    {pageContent?.presenters?.map((presenter: any) => (
                        <Card
                            photo={presenter.photo}
                            name={presenter.name}
                            resume={presenter.resume}
                            linkedin={presenter.linkedin}
                            key={presenter.name}
                        />
                    ))}
                </ContainerPresenters>
            </SectionPresenters>
            <ElementDivider />
            <ContainerBtn>
                <Button
                    color="white"
                    bgColor="var(--blue)"
                    content={pageContent?.CTADemo}
                    cta={handleChangeOpenModal}
                />
            </ContainerBtn>
            <Footer backgroundColor="var(--grey)" />
        </>
    );
};

export default TotvsAgroDay;
