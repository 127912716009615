import styled from 'styled-components';
import { SectionContentText } from '../../styles';

export const SectionAuthorContainer = styled.section`
    display: flex;
    width: 100%;
    justify-content: center;
`;

export const AuthorContainer = styled.section`
    display: flex;
    width: 90%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
`;

export const AuthorImage = styled.img`
    width: 250px;
    height: 250px;
    object-fit: cover;
    object-position: top;
    margin: 20px;

    @media (max-width: 400px) {
        margin: 20px 0;
    }
`;

export const AuthorTitle = styled.h1`
    color: #363636;
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 24px;
`;

export const AuthorLinkedin = styled.a`
    display: flex;
    align-items: center;
    margin-left: 5px;
`;

type HeadlineProps = {
    color?: string;
    src?: string;
};

export const Headline = styled.header<HeadlineProps>`
    line-height: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background${(props) =>
        props.src ? `-image: url(${props.src})` : ': var(--blue)'};
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    min-height: 100vh;
    top: 0;
    @media (max-width: 1255px) {
        height: auto;
        background-size: cover;
    }
    h1 {
        font-size: 2rem;
    }
    p {
        font-size: 1.1rem;
    }
`;

export const HeadContent = styled.div`
    width: 100%;
    height: 100%;
    z-index: 5;
    @media (max-width: 1255px) {
        align-items: center;
        text-align: center;
    }
    ${SectionContentText} {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 20px;
        margin-bottom: 12rem;
    }
`;

export const BackgroundOp = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
`;

export const ContainerPresenters = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @media screen and (max-width: 1600px){
        width: 60%;
    }

    @media screen and (max-width: 600px){
        width: 90%;
    }
`;