import styled from "styled-components";

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-self: center;
  margin-top: 50px;
  width: 100%;
  gap: 30px;
  margin-bottom: 50px;

  @media (max-width: 920px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 530px) {
    grid-template-columns: 1fr;
  }
`;

export const AddJobContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

export const NotFoundImg = styled.img`
  width: 100%;
  max-width: 300px;
  margin-top: 50px;
`;

export const MailLink = styled.a`
  font-family: Nunito;
  font-style: normal;
  font-weight: 300;
  text-decoration: underline;
  color: var(--blue);
  font-size:20px;
  line-height: 30px;

  &:hover{
    cursor: pointer;
  }
`;
