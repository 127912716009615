import React, { ReactNode } from 'react'

import CloseIcon from '../../images/close.svg' 

import { BtnClose, Modal, ModalContainer, ModalContent } from './styles'

interface PropsModalView {
    isOpen: boolean;
    closeModal: () => void;
    children: ReactNode;
}

export function ModalView({ children, isOpen, closeModal }: PropsModalView) {
    return (
        <Modal visible={isOpen}>
            <ModalContainer>
                <BtnClose onClick={closeModal}>
                    <img src={CloseIcon} alt="Fechar modal" />
                </BtnClose>
                <ModalContent>
                    {children}
                </ModalContent>
            </ModalContainer>
        </Modal>
    )
}
