import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--white);
  height: 240px;
  padding: 20px;
  border-radius:2.5px;
  justify-content: space-between;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CardTitle = styled.span`
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 5px;
  color: var(--grey);
`;


export const CardImg = styled.img`
  height: 40px;
`;

export const IconsContainer = styled.div`
  display:flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 10px;
  gap: 2.5px;

  svg{

    transition: 0.2s;

    &:hover{
      cursor: pointer;
      filter: brightness(1.2);
    }
  }
`
