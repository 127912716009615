import styled from 'styled-components';

export const CardContainer = styled.article`
    width: 300px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 10px;
    padding: 20px;
    margin: 15px;
`;

export const CardContainerImage = styled.div`
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #fff;

    -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
`;

export const CardImage = styled.img`
    max-width: 50px;
    max-height: 45px;
`;

export const CardTitle = styled.h3`
    margin: 15px 0;
    text-align: left;
`;

export const CardText = styled.p`
    color: var(--color-gray-dark);
    text-align: left;
`;
