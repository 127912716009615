import styled from "styled-components";

export const ModalContainer = styled.div`
  width: 550px;
  max-width: 100%;
  max-height: 90vh;
  background: var(--white);
  padding: 2.5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
  transition: 0.2s;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: justify;

  svg {
    align-self: flex-end;
    min-height: 25px;
  }

  svg:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--grey-lighter);
    height: 300px !important;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--grey);
  }

  @media (max-width: 500px) {
    width: 100vw;
    min-height: 100vh;
    max-height: 100vh;
    padding: 10vw;
    justify-content: space-between;
    overflow: auto;
  }
`;

export const List = styled.ul`
  list-style-position: inside;
`;

export const ListItem = styled.li`
  font-size: 12px;
  line-height: 14px;
  color: var(--grey-lighter);
`;

export const Link = styled.a`
  align-self: center;
  margin-top: 10px;
  margin-bottom: 30px;
  min-height: 40px;
  background: ${props => props.color? props.color : 'var(--blue)'};
  border-radius: 5px;
  text-decoration: none;
  color: var(--white);
  width: 200px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.2s;

  &:hover {
    filter: brightness(1.2);
  }
`;
